import styled, { css, keyframes } from 'styled-components';

import { ModalContentProps } from '.';
import mq from '../../styles/mq';

export const TitleContainer = styled.div<ModalContentProps>`
  display: flex;
  flex-direction: row;

  img {
    margin-right: 18px;
  }
`;

export const Title = styled.h5<ModalContentProps>`
  display: flex;
  flex: 1;
  justify-content: ${props => {
    if (props.total) {
      return 'flex-start';
    }

    return 'flex-start';
  }};

  color: ${({ theme }) => theme.colors.purpleDefault};
  font-weight: normal;
  font-size: 20px;
`;

export const Total = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3};
  span {
    font-weight: bold;
    font-size: 20px;

    letter-spacing: 0.5px;

    /* Caciq/ Blue/ Midtone */

    color: #17a1a1;
  }
`;

export const ContainerTag = styled.div`
  margin: 5px;
  display: flex;
  width: fit-content;
  height: fit-content;
`;

export const TagWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${mq.custom(
    css`
      display: flex;
      flex-direction: column;
    `,
    { minWidth: 0, maxWidth: 1410 },
  )}
`;

export const ModalContent = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 999999;
`;

export const StyledPopUp = styled.div`
  width: 100%;
  max-width: 408px;
  padding: 20px;
  border-radius: 10px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const TitleAlert = styled.h5`
  font-size: 20px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const TextAlert = styled.p`
  font-size: 16px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const LinkWrapper = styled.div`
  margin-top: 18px;
`;
