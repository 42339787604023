/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { InputHTMLAttributes } from 'react';
import Select from 'react-select';

import * as S from './styles';

interface SelectProps {
  label?: string;
  option?: any;
  date?: boolean;
  customFunction?: any;
}

const SelectInput = ({
  label = '',
  option,
  date = false,
  customFunction = false,
}: SelectProps) => {
  const handleButton = e => {
    if (customFunction) {
      customFunction(e.value);
    } else return null;
  };

  return (
    <S.StyledForm dateProp={date}>
      <label htmlFor="searchbar">{label}</label>
      <Select
        options={option}
        onChange={e => handleButton(e)}
        defaultValue={option[0]}
      />
    </S.StyledForm>
  );
};

export default SelectInput;
