import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import DetailsSection from '../../../../components/DetailsSection';
import Index from '../../../../components/Index';
import PersonalData from '../../../../components/PersonalData';
import TagWrapper from '../../../../components/TagWrapper';
import { useUsersElasticDetails } from '../../../../services/hooks/useUsersElasticDeatil';
import { useUserDetail } from '../../../../services/hooks/useUserDetail';
import * as S from './styles';

const AuthorDetails = () => {
  const { id } = useParams<any>();
  // const [author, setAuthor] = useState<any>({});

  const [birthDate, setBirthDate] = useState('2000-01-01');

  const { data: author, isLoading } = useUserDetail(id);
  const { data: authorElastic }: any = useUsersElasticDetails(
    'authors',
    id || author?.authorId,
  );

  console.log(authorElastic);

  useEffect(() => {
    if (author?.birthDate) {
      const formatBirthDate = author?.birthDate?.split('T');
      setBirthDate(formatBirthDate?.slice(0, 1)?.map(e => e));
    }
  }, [author]);

  const formatedBirthDate = format(
    parseISO(birthDate.toString()),
    'dd/MM/yyyy',
  );

  const postArticles = useMemo(() => {
    if (author?.publishedArticles?.length) {
      return author?.publishedArticles.map(article => article);
    }
    return [];
  }, [author]);

  const authorItems = [
    {
      id: 0,
      title: 'Sobre',
      href: `/usuarios/autores/sobre/${id}`,
      disabled: false,
    },
    {
      id: 1,
      title: 'Cobrança',
      disabled: !author?.subscriptions || false,
      href: `/cobranca/${author?.subscriptions?.id}`,
    },
  ];

  const ICA = authorElastic[0]?._source?.indexes?.['IC-A'];

  const ICH4 = authorElastic[0]?._source?.indexes?.['IC-H4-A'];

  const HALL = authorElastic[0]?._source?.indexes?.hAll;

  const H5 = authorElastic[0]?._source?.indexes?.h5;

  const I10All = authorElastic[0]?._source?.indexes?.i10All;

  const I10 = authorElastic[0]?._source?.indexes?.i105y;

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <DetailsSection paths={authorItems} />
          <S.HeaderWrapper>
            <S.TitleWrapper>
              <S.TypeTitle>Autor</S.TypeTitle>
              <h3>{author?.name || authorElastic[0]?._source?.name}</h3>
            </S.TitleWrapper>

            {ICA ? <Index index={ICA} caciq indexName="IC-A" /> : null}
          </S.HeaderWrapper>

          <S.OtherIndexesWrapper>
            <h4
              style={{
                fontSize: '20px',
                fontWeight: 700,
                letterSpacing: '1px',
              }}
            >
              Outros índices
            </h4>

            {ICH4 ? <Index index={ICH4} indexName="IC-H4-A" /> : ''}
            {HALL ? <Index index={HALL} indexName="Web-h4" /> : ''}
            {/* {H5 && <Index index={H5} indexName="H5" />}
            {I10All && <Index index={I10All} indexName="i10All" />}
            {I10 && <Index index={I10} indexName="i10" />} */}
          </S.OtherIndexesWrapper>

          <S.ColumnWrapper>
            <h4>Dados</h4>

            <S.PersonalDataRow>
              <PersonalData
                title="Nome completo"
                data={author?.name || authorElastic[0]?._source?.name}
                small
              />
              <PersonalData title="CPF" data={author?.CPF} small />
              <PersonalData
                title="Nascimento"
                data={author?.birthDate ? formatedBirthDate : null}
                small
              />
            </S.PersonalDataRow>

            <S.PersonalDataRow>
              <PersonalData title="E-mail" data={author?.email} small />
              {/* <PersonalData
                title="Nome em citação bibliográfica"
                data={author?.nameInCitations}
                small
              />
              <PersonalData title="Orcid" data={author?.orcidToken} small /> */}
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('citations') && (
                    <PersonalData
                      title="Nome em citação"
                      data={item.url}
                      small
                    />
                  ),
              )}
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('orcid') && (
                    <PersonalData title="Orcid" data={item.url} small />
                  ),
              )}
              {authorElastic[0]?._source?.ORCID && (
                <PersonalData
                  title="Orcid"
                  data={authorElastic[0]?._source?.ORCID}
                  small
                />
              )}
            </S.PersonalDataRow>

            <S.PersonalDataRow>
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('linkedin') && (
                    <PersonalData title="LinkedIn" data={item.url} small />
                  ),
              )}
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('lattes') && (
                    <PersonalData title="Lattes" data={item.url} small />
                  ),
              )}
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('website') && (
                    <PersonalData title="Site Oficial" data={item.url} small />
                  ),
              )}
            </S.PersonalDataRow>
            <S.PersonalDataRow style={{ marginBottom: '40px' }}>
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('facebook') && (
                    <PersonalData title="Facebook" data={item.url} small />
                  ),
              )}
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('instagram') && (
                    <PersonalData title="Instagram" data={item.url} small />
                  ),
              )}
              {author?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('twitter') && (
                    <PersonalData title="Twitter" data={item.url} small />
                  ),
              )}
            </S.PersonalDataRow>

            <TagWrapper
              title="Artigos do Autor"
              tags={
                author?.publishedArticles ||
                authorElastic[0]?._source?.articlesRel
              }
              muttable
              total={
                author?.publishedArticles?.length ||
                authorElastic[0]?._source?.articlesRel?.length
              }
            />

            <TagWrapper
              title="Programas de Pós-graduação"
              tags={author?.ppgs}
              total={author?.ppgs?.length}
            />

            <TagWrapper
              title="Citações mais usadas"
              tags={author?.mostCitedArticles}
              total={author?.mostCitedArticles?.length}
            />

            <TagWrapper
              title="Palavras-chave mais usadas"
              tags={author?.mostCitedKeyWords}
              total={author?.mostCitedKeyWords?.length}
            />
          </S.ColumnWrapper>
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default AuthorDetails;
