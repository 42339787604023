/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import SelectInput from '../../../../components/Select';
import * as S from './styles';
import api from '../../../../services/api';
import Button from '../../../../components/Button';
import PopUpFindName from '../../../../components/PopUpFindName';
import { ReactComponent as SearchIcon } from '../../../../assets/search-right.svg';
import closeImg from '../../../../assets/close.svg';

export interface NewSubscriptionModalProps {
  isOpen: boolean;
  formResponse?: any;
  formRequest?: any;
  onRequestClose: () => void;
  alertContent?: {
    title?: string;
    attachment?: boolean;
    text?: string;
    defaultButtonText?: string;
    ghostButtonText?: string;
    dangerButtonText?: string;
    namesFound?: number;
    onClickDefault?: () => void;
    onClickGhost?: () => void;
    onClickDanger?: () => void;
  };
  inputs?: {
    type?: 'input' | 'select' | 'upload' | 'preview';
    id?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    defaultValue?: string;
    isEditable?: boolean;
  }[];
}

interface formProps {
  name?: string;
  price?: number;
  recurrence?: string;
  ignorePayment?: boolean;
}

const selectOptions = [
  {
    value: 'researcher',
    label: 'Pesquisador',
  },
  {
    value: 'author',
    label: 'Autor',
  },
  {
    value: 'ppg',
    label: 'PPG',
  },
  {
    value: 'journal',
    label: 'Periódico',
  },
];

const NewSubscriptionModal: React.FC<NewSubscriptionModalProps> = ({
  ...props
}) => {
  const inputRef = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<formProps>();
  const [userList, setUserList] = useState<any>([]);
  const [getBasicRole, setGetBasicRole] = useState('researcher');
  const [recurrence, setRecurrence] = useState('monthly');
  const [searchName, setSearchName] = useState(false);
  const [ignorePayment, setIgnorePayment] = useState(false);
  const [newValue, setNewValue] = useState<any>();

  useEffect(() => {
    if (newValue?.label) {
      setValue('name', newValue?.label);
    }
  }, [newValue]);

  const onSubmit: SubmitHandler<formProps> = async data => {
    try {
      delete data.name;

      const allData = {
        price: ignorePayment ? 7.77 : Number(data.price),
        recurrence,
        payerId: newValue?.value,
        ignorePayment: data.ignorePayment,
        accessType: getBasicRole || 'researcher',
        allowedUsers: userList,
      };

      console.log({ allData });

      await api.post('/dashboard/subscriptions/', allData);
      toast.success('Plano Criado');
      window.location.reload();
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  async function handleNewFile(file) {
    const currentFile = file.files[0];

    try {
      const formData = new FormData();
      formData.append('file', currentFile);

      const response = await api.post('/upload/allowedUsers/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUserList(response.data);
    } catch (err: any) {
      toast.error(err.message);
    }
  }

  function handleSelectFile() {
    inputRef.current.click();
  }

  function handleFindName() {
    setSearchName(true);
  }

  return (
    <>
      {searchName && (
        <PopUpFindName
          isOpen={searchName}
          userType="journals"
          setPopUpState={setSearchName}
          handleNewValue={setNewValue}
        />
      )}
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            top: '40px',
            height: 'fit-content',
            margin: 'auto',
            width: '100%',
            maxWidth: '636px',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <button
          type="button"
          onClick={props.onRequestClose}
          style={{
            top: 20,
            right: 20,
            position: 'absolute',
            background: 'none',
            border: 'none',
            zIndex: 9999,
          }}
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>
        <S.ModalContent>
          <>
            <S.StyledPopUp {...props}>
              <S.TitleAlert>{props.alertContent?.title}</S.TitleAlert>

              <form onSubmit={handleSubmit(onSubmit)}>
                <S.InputWrapper {...props}>
                  <ul>
                    <li className="find-name">
                      <div style={{ flexGrow: 1 }}>
                        <label htmlFor={props?.inputs[0]?.name}>Pagante</label>
                        <input
                          id={props?.inputs[0]?.name}
                          style={{ width: '100%' }}
                          {...register('name')}
                          placeholder={props.inputs[0].placeholder}
                          disabled
                        />
                      </div>

                      <Button onClick={handleFindName}>
                        <SearchIcon />
                      </Button>
                    </li>
                    <li>
                      <SelectInput
                        label={props?.inputs[1]?.label}
                        customFunction={setGetBasicRole}
                        option={selectOptions}
                      />
                    </li>

                    {!ignorePayment && (
                      <li className="select-price-container">
                        <div className="radio-container">
                          <SelectInput
                            label="Recorrência"
                            customFunction={setRecurrence}
                            option={[
                              { label: 'Mensal', value: 'monthly' },
                              { label: 'Anual', value: 'yearly' },
                            ]}
                          />
                        </div>

                        <div className="price-container">
                          <label htmlFor="price"> Preço</label>
                          <input
                            type="number"
                            id="price"
                            placeholder="29,99"
                            step="0.01"
                            min={1}
                            {...register('price')}
                          />
                        </div>
                      </li>
                    )}

                    <li>
                      <div className="ignore-payment-container">
                        <input
                          type="checkbox"
                          id="ignorePayment"
                          name="ignorePayment"
                          {...register('ignorePayment')}
                          onChange={e => setIgnorePayment(e.target.checked)}
                        />
                        <label htmlFor="ignorePayment">
                          Tornar plano grátis{' '}
                          <span>(Ignora método de pagamento via Stripe)</span>
                        </label>
                      </div>
                    </li>

                    <li style={{ paddingTop: '4px' }}>
                      <label htmlFor={props?.inputs[2]?.name}>
                        {props?.inputs[2]?.label}
                      </label>
                      <div style={{ display: 'flex', gap: '18px' }}>
                        <div
                          className="input-container"
                          style={{ flexGrow: 1 }}
                        >
                          {userList.length <= 0 && (
                            <input
                              id={props?.inputs[2]?.name}
                              type="file"
                              ref={inputRef}
                              style={{ width: '100%', padding: 2, border: 0 }}
                              onChange={file => handleNewFile(file.target)}
                              placeholder={props.inputs[2].placeholder}
                            />
                          )}

                          {userList.length > 0 && [] && (
                            <ul className="users-container">
                              {userList.map(user => (
                                <li>
                                  <span>{user.name}</span>
                                  <span>{user.email}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <Button color="blueMidtone" onClick={handleSelectFile}>
                          {userList.length > 0
                            ? 'Escolher outro arquivo'
                            : 'Escolher arquivo'}
                        </Button>
                      </div>
                      <a
                        href="https://storage.googleapis.com/caciq_uploads/adicionarUsu%C3%A1rios.xlsx"
                        style={{
                          color: '#777',
                          fontSize: '14px',
                          fontFamily: 'Work Sans, sans-serif',
                          textDecoration: 'underline',
                        }}
                      >
                        Download do modelo
                      </a>
                      {userList == null && (
                        <h6 style={{ marginTop: '20px' }}>
                          Foram encontrados
                          {userList !== [] ? ` ${userList?.length} ` : 0}
                          nomes
                        </h6>
                      )}
                    </li>
                  </ul>
                </S.InputWrapper>

                <S.ButtonWrapper {...props}>
                  {props.alertContent?.dangerButtonText && (
                    <Button
                      color="redMidtone"
                      shadow={false}
                      onClick={props.alertContent?.onClickDanger}
                      size="small"
                    >
                      {props.alertContent?.dangerButtonText}
                    </Button>
                  )}

                  {props.alertContent?.ghostButtonText && (
                    <Button
                      color="blueMidtone"
                      shadow={false}
                      onClick={props.alertContent?.onClickGhost}
                      size="small"
                      ghost
                    >
                      {props.alertContent?.ghostButtonText}
                    </Button>
                  )}

                  {props.alertContent?.defaultButtonText && (
                    <Button
                      color="blueMidtone"
                      shadow={false}
                      size="small"
                      typeOf="submit"
                    >
                      {props.alertContent?.defaultButtonText}
                    </Button>
                  )}
                </S.ButtonWrapper>
              </form>
            </S.StyledPopUp>
          </>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default NewSubscriptionModal;
