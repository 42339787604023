import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { GrClose } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import * as S from './styles';
import api from '../../services/api';
import Button from '../Button';
import closeImg from '../../assets/close.svg';
import FetchingAnimation from '../FetchingAnimation';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';

export interface NotificationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  refetch: any;
  notificationContent?: {
    type?: string;
    action?: string;
    text?: string;
    id?: string;
    redirect?: string;
    read?: boolean;
    onClick?: () => void;
  }[];
}

const NotificationModal: React.FC<NotificationModalProps> = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  async function handleRedirect(id) {
    try {
      setIsLoading(true);
      const response = await api.patch(`/dashboard/notifications/read/${id}`);
      props.refetch();
      history.push(`/redirect/${props?.notificationContent[0]?.redirect}`);
      setIsLoading(false);
      props.onRequestClose();
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  }
  async function handleReadMessage(id) {
    try {
      setIsLoading(true);
      const response = await api.patch(`/dashboard/notifications/read/${id}`);
      props.refetch();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  }

  return (
    <>
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
            top: '70px',
            left: 'calc(100vw - 850px)',
            height: 'fit-content',
            maxWidth: '658px',
            width: '100%',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            padding: '0',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <S.StyledPopUp>
          <S.TitleAlert>
            {props.title} {isLoading && <FetchingAnimation />}
          </S.TitleAlert>
          <div className="tableLike">
            <div className="tableHead">
              <p>Tipo</p>
              <p>Ação</p>
              <p>Visualizar</p>
            </div>

            {props.notificationContent &&
              props.notificationContent.map(item => (
                <div
                  className={
                    !item.read ? 'unreadMessage tableBody' : 'tableBody'
                  }
                >
                  <p>{item.type === 'Conteúdo' ? 'Verificação' : item.type}</p>
                  <p>{item.action}</p>
                  <p>
                    {item.type === 'Conteúdo' ? (
                      <Button
                        color="blueMidtone"
                        onClick={() => handleRedirect(item.id)}
                      >
                        <ArrowRight style={{ fill: 'white' }} />
                      </Button>
                    ) : (
                      <Button
                        color="blueMidtone"
                        onClick={() => handleReadMessage(item.id)}
                      >
                        <span className="close-icon">
                          <GrClose />
                        </span>
                      </Button>
                    )}
                  </p>
                </div>
              ))}
          </div>
          {!props.notificationContent && (
            <div className="noNotifications">Sem notificações</div>
          )}
        </S.StyledPopUp>
      </Modal>
    </>
  );
};

export default NotificationModal;
