/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import getClient from '../client/elasticsearch';

export function useUsersElasticDetails(search: string, id: string) {
  return useQuery(['users', search, id], () => useUsersE(search, id), {
    initialData: [],
  });
}

async function useUsersE(search, id) {
  const response = await getClient().search({
    index: search,
    size: 5,
    body: {
      query: {
        bool: {
          should: [
            {
              match: {
                uniqId: id,
              },
            },
            {
              match: {
                id,
              },
            },
          ],
        },
      },
    },
  });
  const data = await response.hits.hits;
  return data;
}
