import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as NotificationOff } from '../../assets/notification-off.svg';
import { ReactComponent as NotificationOn } from '../../assets/notification-on.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { ReactComponent as Return } from '../../assets/return.svg';
import api from '../../services/api';

import * as S from './styles';
import NotificationModal from '../NotificationModal';

interface HeaderProps {
  userName: string;
  hasNotification?: boolean;
  isHomePath?: boolean;
}

const Header: React.FC<HeaderProps> = props => {
  const [isNewNotification, setIsNewNotification] = useState(false);

  const { data: notifications, refetch } = useQuery(
    ['notifications'],
    async () => {
      const response = await api.get('/dashboard/notifications/');
      const data: any = await response.data;
      const notificationList = data.results.map(notification => ({
        type: notification.type,
        action: notification.message,
        read: notification.read,
        id: notification.id,
        redirect: notification.redirect,
      }));

      return notificationList;
    },
    {
      refetchInterval: 500 * 10,
    },
  );

  useEffect(() => {
    const getNotifications = notifications?.filter(e => !e.read);
    if (getNotifications?.length > 0) setIsNewNotification(true);
  }, [notifications]);

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  function handleCloseNotificationModal() {
    setIsNotificationModalOpen(false);
  }
  function handleOpenNotificationModal() {
    setIsNotificationModalOpen(true);
  }

  const history = useHistory();
  const location = useLocation();

  const { signOut } = useAuth();
  return (
    <>
      <S.Header>
        <S.ButtonWrapper>
          {location.pathname !== '/' ? (
            <S.ReturnButton type="button" onClick={() => history.goBack()}>
              <Return />
            </S.ReturnButton>
          ) : null}

          {/* <S.SearchButton type="button">
            <img src={Search} alt="Buscar" />
          </S.SearchButton> */}
        </S.ButtonWrapper>
        <S.NotificationWrapper>
          <p>
            Bem vindo, <span>{props.userName}</span>
          </p>

          {isNewNotification ? (
            <button type="button" onClick={handleOpenNotificationModal}>
              <NotificationOn />
            </button>
          ) : (
            <button type="button" onClick={handleOpenNotificationModal}>
              <NotificationOff />
            </button>
          )}
          <S.LogoutButton type="button" onClick={signOut}>
            Logout <ArrowRight style={{ marginLeft: 8 }} fill="#17A1A1" />
          </S.LogoutButton>
        </S.NotificationWrapper>
        <NotificationModal
          isOpen={isNotificationModalOpen}
          refetch={refetch}
          onRequestClose={handleCloseNotificationModal}
          title="Notificações"
          notificationContent={notifications}
        />
      </S.Header>
    </>
  );
};

export default Header;
