import React from 'react';
import EditButton from '../EditButton';
import * as S from './styles';

export interface SubscriptionsCardProps {
  title?: string;
  hideEditButton?: boolean;
  value?: any;
}

const SimpleCard: React.FC<SubscriptionsCardProps> = props => {
  return (
    <>
      <S.MainContainer>
        <S.Container>
          <S.RowWrapper>
            <S.Title>{props.title}</S.Title>
            {!props.hideEditButton && <EditButton />}
          </S.RowWrapper>
          <S.ValueWrapper>
            {props.value ? <h3>{props.value}</h3> : <p>Dado não encontrado</p>}
          </S.ValueWrapper>
        </S.Container>
      </S.MainContainer>
    </>
  );
};

export default SimpleCard;
