import React from 'react';
import * as S from './styles';
import { IIndex } from '../../models';

export interface IndexProps {
  index?: any;
  caciq?: boolean;
  qualis?: boolean;
  indexName?: string;
}

const Index = ({
  index = 0,
  qualis = false,
  indexName = '',
  ...props
}: IndexProps) => {
  return (
    <>
      <S.IndexContainer {...props}>
        <h2>{qualis ? index : Number(index).toFixed(2)}</h2>
        <h6>{qualis ? 'Qualis' : indexName}</h6>
      </S.IndexContainer>
    </>
  );
};

export default Index;
