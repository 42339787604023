/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import AlertModal from '../../components/AlertModal';
import Button from '../../components/Button';
import DataTable from '../../components/DataTable';
import EditModal from '../../components/EditModal';
import GrowthCard from '../../components/GrowthCard';
import SubscriptionsCard from '../../components/SubscriptionsCard';
import TotalCountCard from '../../components/TotalCountCard';
import api from '../../services/api';
import NewSubscriptionModal from './components/newSubscriptionModal';

import * as S from './styles';

const Subscriptions: React.FC = () => {
  const [modalResponse, setModalResponse] = useState({
    freeTrialDays: 0,
    pfPriceYearly: 0,
    pfPriceMonthly: 0,
    pjPriceYearly: 0,
    pjPriceMonthly: 0,
    pjPPGnJNPriceYearly: 0,
  });

  const { data: prices } = useQuery(
    ['price'],

    async () => {
      const response = await api.get('/subscriptions/prices');
      setModalResponse(response.data);
      return response.data;
    },

    {
      initialData: {
        pfPriceMonthly: 0,
        pfPriceYearly: 0,
        pjPriceMonthly: 0,
        pjPriceYearly: 0,
      },
    },
  );

  const {
    data: subscriptions,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    'subscriptions',
    async () => {
      const response = await api.get('/dashboard/subscriptions');
      const data = await response.data;
      console.log(data);
      return data;
    },
    {
      initialData: [],
      // staleTime: 1000 * 60,
    },
  );

  const totalPf = subscriptions.filter(
    e => e.accessType === 'researcher' || e.accessType === 'author',
  );

  const totalPj = subscriptions.filter(
    e => e.accessType === 'journal' || e.accessType === 'ppg',
  );

  const [pfSubscription, setPFSubscription] = useState(false);
  const [pjSubscription, setPJSubscription] = useState(false);

  function handleEditPF() {
    setPFSubscription(true);
  }
  function handleEditPJ() {
    setPJSubscription(true);
  }
  function handleCloseSubscriptionValueModal() {
    setPFSubscription(false);
    setPJSubscription(false);
    setSubscriptionEditable(true);
  }

  const [
    isValueEditionConfirmationModalOpen,
    setValueEditionConfirmationModalOpen,
  ] = useState(false);

  function handleSaveValueEditionModal() {
    setPFSubscription(false);
    setPJSubscription(false);
    setValueEditionConfirmationModalOpen(true);
  }

  async function handleCloseValueEditionConfirmationModal() {
    setValueEditionConfirmationModalOpen(false);
  }

  const [isSubscriptionEditable, setSubscriptionEditable] = useState(true);
  function handleMakeEditable() {
    setSubscriptionEditable(false);
  }

  const [isAlertConfirmationModalOpen, setAlertConfirmationModalOpen] =
    useState(false);

  async function handleOpenAlertConfirmationModal() {
    const pjCosts = {
      freeTrialDays: 14,
      pfPriceYearly: modalResponse.pfPriceYearly || 0,
      pfPriceMonthly: modalResponse.pfPriceMonthly || 0,
      pjPriceYearly: modalResponse.pjPriceYearly || 0,
      pjPriceMonthly: modalResponse.pjPriceMonthly || 0,
    };
    try {
      const response = await api.post('/dashboard/subscriptions/config', {
        ...modalResponse,
        ...pjCosts,
      });
      setAlertConfirmationModalOpen(true);
      setValueEditionConfirmationModalOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  }
  function handleCloseAlertConfirmationModal() {
    setAlertConfirmationModalOpen(false);
  }

  const [isNewSubscriptionModalOpen, setNewSubscriptionModalOpen] =
    useState(false);
  function handleOpenNewSubscriptionModal() {
    setNewSubscriptionModalOpen(true);
  }
  function handleCloseNewSubscriptionModal() {
    setNewSubscriptionModalOpen(false);
  }
  function handleConfirmNewSubscription() {
    setNewSubscriptionModalOpen(false);
    setNewSubscriptionAlertConfirmationModalOpen(true);
  }

  const [
    isNewSubscriptionAlertConfirmationModalOpen,
    setNewSubscriptionAlertConfirmationModalOpen,
  ] = useState(false);
  function handleOpenNSubscriptionAlertConfirmationModal() {
    setNewSubscriptionModalOpen(true);
  }
  function handleCloseSubscriptionAlertConfirmationModal() {
    setNewSubscriptionModalOpen(false);
  }

  const inputsPF = [
    {
      id: '1',
      label: 'Preço Anual',
      name: 'pfPriceYearly',
      defaultValue: modalResponse.pfPriceYearly.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      isEditable: isSubscriptionEditable,
    },
    {
      id: '2',
      label: 'Preço Mensal',
      name: 'pfPriceMonthly',
      defaultValue: modalResponse.pfPriceMonthly.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      isEditable: isSubscriptionEditable,
    },
  ];
  const inputsPJ = [
    {
      id: '1',
      label: 'Preço Anual',
      name: 'pjPriceYearly',
      defaultValue: modalResponse.pjPriceYearly.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      isEditable: isSubscriptionEditable,
    },
    {
      id: '2',
      label: 'Preço Mensal',
      name: 'pjPriceMonthly',
      defaultValue: modalResponse.pjPriceMonthly.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      isEditable: isSubscriptionEditable,
    },
  ];
  const inputsNewSubscription = [
    {
      id: '1',
      label: 'Nome do responsável financeiro',
      name: 'planName',
      placeholder: 'Nome do responsável financeiro',
    },
    {
      id: '2',
      label: 'Tipo de Acesso',
      name: 'accessType',
      placeholder: 'Pesquisador/Autor/Periódico/PPG',
    },
    {
      id: '3',
      label: 'Anexar Planilha de contatos',
      name: 'accessType',
      placeholder: 'Anexar aquivo',
    },
  ];

  const researcherSize = subscriptions?.filter(
    e => e.accessType === 'researcher',
  );
  const authorSize = subscriptions?.filter(e => e.accessType === 'author');
  const journalSize = subscriptions?.filter(e => e.accessType === 'journal');
  const ppgSize = subscriptions?.filter(e => e.accessType === 'ppg');

  const tableColumns = [
    {
      title: 'Tipo de plano',
      field: 'subscriptions',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '20%', color: '#A5ADBA' },
    },
    {
      title: 'Código do Plano',
      field: 'shortcode',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '20%', color: '#A5ADBA' },
    },
    {
      title: 'Nº de usuários',
      field: 'users',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '15%', color: '#A5ADBA' },
    },
    {
      title: 'Ação',
      field: 'action',
      filtering: false,
      sorting: false,
      searchable: false,
      cellStyle: { width: '10%' },
    },

    {
      title: (
        <Button onClick={handleOpenNewSubscriptionModal}>
          Adicionar Planos
        </Button>
      ),
      cellStyle: { width: '16%', color: '#A5ADBA' },
    },
  ];

  const tableData = useMemo(() => {
    return subscriptions.map(e => {
      return {
        subscriptions:
          e.accessType === 'researcher'
            ? ' Pesquisador '
            : e.accessType === 'author'
            ? ' Autor'
            : e.accessType === 'ppg'
            ? ' PPG'
            : e.accessType === 'journal'
            ? 'Periódico'
            : ' Sem Plano',
        shortcode: e.shortCode,
        users: e.users.length,
        action: (
          <Button link href={`/assinaturas/sobre/${e.id}`}>
            Ver Mais
          </Button>
        ),
      };
    });
  }, [subscriptions]);

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <S.RowWrapper>
            <SubscriptionsCard
              title="Valor Assinaturas PF"
              annualValue={modalResponse?.pfPriceYearly}
              hideMonth={false}
              monthlyValue={modalResponse?.pfPriceMonthly}
              onClick={handleEditPF}
            />
            <SubscriptionsCard
              title="Valor Assinaturas PJ"
              hideMonth
              annualValue={modalResponse?.pjPPGnJNPriceYearly}
              monthlyValue={0}
              onClick={handleEditPJ}
            />
          </S.RowWrapper>
          <S.RowWrapper>
            <TotalCountCard
              title="Total de assinaturas Pessoa Física"
              value={totalPf.length}
            />
            <TotalCountCard
              title="Total de assinaturas Pessoa Jurídica"
              value={totalPj.length}
            />
          </S.RowWrapper>
          <S.RowWrapper>
            <GrowthCard
              type="researcher"
              value={researcherSize.length}
              growth={2.5}
            />
            <GrowthCard type="author" value={authorSize.length} growth={-2.5} />
            <GrowthCard
              type="journal"
              value={journalSize.length}
              growth={-2.5}
            />
            <GrowthCard type="ppg" value={ppgSize.length} growth={-2.5} />
          </S.RowWrapper>

          <DataTable
            tableTitle="Planos de Assinatura"
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
          />
        </S.ContentWrapper>
        <EditModal
          isOpen={pfSubscription}
          onRequestClose={handleCloseSubscriptionValueModal}
          formResponse={setModalResponse}
          formRequest={modalResponse}
          alertContent={{
            title: 'Valor Assinatura PF',
            ghostButtonText: !isSubscriptionEditable ? null : 'Voltar',
            onClickGhost: handleCloseSubscriptionValueModal,
            dangerButtonText: !isSubscriptionEditable ? 'Cancelar' : null,
            onClickDanger: handleCloseSubscriptionValueModal,
            defaultButtonText: !isSubscriptionEditable ? 'Salvar' : 'Editar',
            onClickDefault: !isSubscriptionEditable
              ? handleSaveValueEditionModal
              : handleMakeEditable,
          }}
          inputs={inputsPF}
        />
        <EditModal
          isOpen={pjSubscription}
          onRequestClose={handleCloseSubscriptionValueModal}
          formResponse={setModalResponse}
          formRequest={modalResponse}
          alertContent={{
            title: 'Valor Assinatura PJ',
            ghostButtonText: !isSubscriptionEditable ? null : 'Voltar',
            onClickGhost: handleCloseSubscriptionValueModal,
            dangerButtonText: !isSubscriptionEditable ? 'Cancelar' : null,
            onClickDanger: handleCloseSubscriptionValueModal,
            defaultButtonText: !isSubscriptionEditable ? 'Salvar' : 'Editar',
            onClickDefault: !isSubscriptionEditable
              ? handleSaveValueEditionModal
              : handleMakeEditable,
          }}
          inputs={inputsPJ}
          isPJ
        />
        <AlertModal
          isOpen={isValueEditionConfirmationModalOpen}
          onRequestClose={handleCloseValueEditionConfirmationModal}
          alertContent={{
            title: 'Confirmar Mudança de Valor',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue risus sagittis neque condimentum bibendum viverra. Tellus quisque sed enim faucibus amet, eu, sed vulputate. Leo tellus enim mi.',
            dangerButtonText: 'Cancelar',
            onClickDanger: handleCloseValueEditionConfirmationModal,
            defaultButtonText: 'Confirmar',
            onClickDefault: handleOpenAlertConfirmationModal,
          }}
          reverseButtonRow
        />
        <AlertModal
          isOpen={isAlertConfirmationModalOpen}
          onRequestClose={handleCloseAlertConfirmationModal}
          alertContent={{
            title: 'Valor Alterado',
            defaultButtonText: 'Voltar',
            onClickDefault: handleCloseAlertConfirmationModal,
          }}
        />

        <NewSubscriptionModal
          isOpen={isNewSubscriptionModalOpen}
          onRequestClose={handleCloseNewSubscriptionModal}
          alertContent={{
            title: 'Novo plano de Assinatura',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue risus sagittis neque condimentum bibendum viverra. Tellus quisque sed enim faucibus amet, eu, sed vulputate. Leo tellus enim mi.',
            ghostButtonText: 'Voltar',
            onClickGhost: handleCloseNewSubscriptionModal,
            defaultButtonText: 'Confirmar',
            onClickDefault: handleConfirmNewSubscription,
          }}
          inputs={inputsNewSubscription}
        />
        <AlertModal
          isOpen={isNewSubscriptionAlertConfirmationModalOpen}
          onRequestClose={handleCloseSubscriptionAlertConfirmationModal}
          alertContent={{
            title: 'Plano gerado',
            text: 'Código do plano: ',
            planCode: 'XXXXXX',
            defaultButtonText: 'Voltar',
            onClickDefault: handleCloseSubscriptionAlertConfirmationModal,
          }}
        />
      </S.MainContainer>
    </>
  );
};

export default Subscriptions;
function setTableData(arg0: any[]) {
  throw new Error('Function not implemented.');
}
