import styled, { css } from 'styled-components';

import { PaymentHistoryProps } from '.';
import mq from '../../styles/mq';

export const StyledPopUp = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .noNotifications {
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 0.9rem;
    margin-top: 18px;
  }

  .tableLike {
    width: 100%;
    max-height: 360px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: rgba(0, 0, 0, 0.2);
    }

    .tableHead,
    .tableBody {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.grey4};
      text-align: start;

      p:first-child {
        width: 30%;
      }

      p:nth-child(2) {
        width: 50%;
      }

      p:last-child {
        width: 20%;
      }
    }

    .tableHead {
      p {
        color: ${({ theme }) => theme.colors.grey3};
      }
      margin-bottom: 10px;
    }

    .tableBody {
      padding: 20px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      button {
        background: none;
        border: 0;
      }
    }
  }
`;

export const TitleAlert = styled.h5`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.grey2};
`;
