import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button';
import DetailsSection from '../../../../components/DetailsSection';
import Index from '../../../../components/Index';
import PersonalData from '../../../../components/PersonalData';
import TagWrapper from '../../../../components/TagWrapper';
import { useUsersElasticDetails } from '../../../../services/hooks/useUsersElasticDeatil';
import AlertModal from '../../../../components/AlertModal';
import api from '../../../../services/api';
import { useUserDetail } from '../../../../services/hooks/useUserDetail';
import * as S from './styles';

const PPGsDetails = () => {
  const { id } = useParams<any>();
  const { data: ppg } = useUserDetail(id);
  const { data: ppgElastic }: any = useUsersElasticDetails('ppgs', id);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isVerifyedAccountModalOpen, setIsVerifyedAccountModalOpen] =
    useState(false);

  console.log(ppgElastic);

  const ppgItems = [
    {
      id: 0,
      title: 'Sobre',
      href: `/usuarios/ppgs/sobre/${id}`,
      disabled: false,
    },
    {
      id: 1,
      title: 'Cobrança',
      disabled: !ppg?.subscriptions || false,

      href: `/cobranca/${ppg?.subscriptions?.id}`,
    },
  ];

  function handleOpenModal() {
    setIsVerificationModalOpen(true);
  }

  function handleCloseModal() {
    setIsVerificationModalOpen(false);
  }

  const openVerificationFile = () => {
    window.open(ppg?.verifications[0]?.url);
  };

  function handleCloseVerifyedModal() {
    setIsVerifyedAccountModalOpen(false);
  }

  async function handleOpenVerifyedModal() {
    try {
      const approve = {
        verificationId: ppg?.verifications[0]?.id,
        approve: true,
      };

      const reponse = await api.post(
        '/dashboard/verification/approve/',
        approve,
      );
      toast.success('Periódico Aprovado');
      setIsVerificationModalOpen(false);
      setIsVerifyedAccountModalOpen(true);
      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  console.log(ppg);

  const ICP = ppgElastic[0]?._source?.indexes?.['IC-P'];

  const ICHP = ppgElastic[0]?._source?.indexes?.['IC-H4-P'];

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <DetailsSection paths={ppgItems} />
          <S.HeaderWrapper>
            <S.TitleWrapper>
              <S.TypeTitle>Programa de pós graduação</S.TypeTitle>
              <h3>{ppg?.name || ppgElastic[0]?._source?.name}</h3>
            </S.TitleWrapper>
            {ICP ? <Index index={ICP} caciq indexName="IC-P" /> : null}
            {ICHP ? <Index index={ICHP} caciq indexName="IC-H4-P" /> : null}
          </S.HeaderWrapper>

          {ppg?.verifications[0]?.url && (
            <S.VerifyContainer>
              <S.InfoContainer>
                <h4 className="info-titles">
                  Arquivo de <br /> Verificação
                </h4>
                <S.InfoWrapper>
                  <p>{ppg?.verifications[0]?.url} </p>
                </S.InfoWrapper>
              </S.InfoContainer>
              <S.ButtonWrapper>
                <Button
                  rightImage="download"
                  ghost
                  size="small"
                  onClick={openVerificationFile}
                >
                  Download
                </Button>

                <Button color="blueMidtone" shadow onClick={handleOpenModal}>
                  Verificar
                </Button>
              </S.ButtonWrapper>
            </S.VerifyContainer>
          )}

          <S.ColumnWrapper>
            <h4>Dados</h4>
            <S.PersonalDataRow>
              <PersonalData title="E-mail" data={ppg?.email} small />
              <PersonalData
                title="Nome da Universidade"
                data={ppg?.university}
                small
              />
              <PersonalData
                title="Site Oficial"
                data={ppg?.socials?.map(
                  item => item.name.toLowerCase().includes('site') && item.url,
                )}
                small
              />
            </S.PersonalDataRow>

            <S.PersonalDataRow>
              <PersonalData
                title="Coordenador do curso"
                data={ppg?.socials?.map(
                  item =>
                    item.name.toLowerCase().includes('coordinator') && item.url,
                )}
                small
              />
              <PersonalData
                title="Área de avaliação"
                data={ppg?.socials?.map(
                  item =>
                    item.name.toLowerCase().includes('valuation') && item.url,
                )}
                small
              />
              <PersonalData
                title="Linkedin"
                data={ppg?.socials?.map(
                  item =>
                    item.name.toLowerCase().includes('linkedin') && item.url,
                )}
                small
              />
            </S.PersonalDataRow>

            <S.PersonalDataRow>
              <PersonalData
                title="Facebook"
                data={ppg?.socials?.map(
                  item =>
                    item.name.toLowerCase().includes('facebook') && item.url,
                )}
                small
              />
              <PersonalData
                title="Instagram"
                data={ppg?.socials?.map(
                  item =>
                    item.name.toLowerCase().includes('instagram') && item.url,
                )}
                small
              />
              <PersonalData
                title="Twitter"
                data={ppg?.socials?.map(
                  item =>
                    item.name.toLowerCase().includes('twitter') && item.url,
                )}
                small
              />
            </S.PersonalDataRow>

            <S.PersonalDataRow style={{ marginBottom: '40px' }}>
              {ppg?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('facebook') && (
                    <PersonalData title="Facebook" data={item.url} small />
                  ),
              )}
              {ppg?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('twitter') && (
                    <PersonalData title="Twitter" data={item.url} small />
                  ),
              )}
              {ppg?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('instagram') && (
                    <PersonalData title="Instagram" data={item.url} small />
                  ),
              )}
            </S.PersonalDataRow>

            <TagWrapper
              title="Professores Vinculados"
              tags={ppgElastic[0]?._source?.authorsRel?.map(
                author => author.name,
              )}
              total={ppgElastic[0]?._source?.authorsRel?.length}
            />

            <TagWrapper
              title="Citações mais usadas"
              tags={ppgElastic[0]?._source?.mostUsedCitations}
              total={ppgElastic[0]?._source?.mostUsedCitations?.length}
            />

            <TagWrapper
              title="Palavras-chave mais usadas"
              tags={ppgElastic[0]?._source?.mostUsedKeywords}
              total={ppgElastic[0]?._source?.mostUsedKeywords?.length}
            />
          </S.ColumnWrapper>
        </S.ContentWrapper>
      </S.MainContainer>
      <AlertModal
        isOpen={isVerificationModalOpen}
        onRequestClose={handleCloseModal}
        alertContent={{
          title: 'Confirmar Verificação',
          document: 'www',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue risus sagittis neque condimentum bibendum viverra. Tellus quisque sed enim faucibus amet, eu, sed vulputate. Leo tellus enim mi.',
          defaultButtonText: 'Verificar',
          dangerButtonText: 'Cancelar',
          onClickDanger: handleCloseModal,
          onClickDoc: openVerificationFile,
          onClickDefault: handleOpenVerifyedModal,
        }}
      />
      <AlertModal
        isOpen={isVerifyedAccountModalOpen}
        onRequestClose={handleCloseVerifyedModal}
        alertContent={{
          title: 'Conta Verificada',
          defaultButtonText: 'Voltar',
          onClickDefault: handleCloseVerifyedModal,
        }}
      />
    </>
  );
};

export default PPGsDetails;
