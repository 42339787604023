import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import Button from '../Button';
import PaymentTableLine, { PaymentTableLineProps } from './components';
import PaymentHistory from '../PaymentHistoryModal';
import * as S from './styles';

export interface PaymentHistoryCardProps {
  paymentHistory?: any;
}

const PaymentHistoryCard: React.FC<PaymentHistoryCardProps> = props => {
  const [openModal, setOpenModal] = useState(false);

  function requestCloseModal() {
    setOpenModal(false);
  }

  function requestOpenModal() {
    setOpenModal(true);
  }

  const parseStripePrice = (price: number) => {
    if (!price) return 0;
    const value = price.toString();
    const inteiro = value.slice(0, -2);
    const decimais = value.slice(-2);
    const final = Number(`${inteiro}.${decimais}`);
    return final;
  };

  return (
    <>
      <S.Container>
        <S.Title>Pagamentos</S.Title>
        <S.HeaderWrapper>
          <S.LeftContent>
            <p>Data</p>
          </S.LeftContent>
          <S.RightContent>
            <p>Valor</p>
          </S.RightContent>
          <S.RightContent>
            <p>PDF</p>
          </S.RightContent>
        </S.HeaderWrapper>

        <S.ContentContainer>
          {props.paymentHistory?.slice(0, 5).map((item, index) => (
            <PaymentTableLine
              key={item.paymentId}
              downloadPDF={item}
              paymentDate={format(
                parseISO(new Date(item?.created).getUTCDate().toString()),

                'dd/MM/yyyy',
              )}
              price={parseStripePrice(item?.amount_paid)?.toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                },
              )}
            />
          ))}
        </S.ContentContainer>

        <S.ButtonContainer>
          <Button
            size="small"
            color="blueMidtone"
            rightImage="arrow"
            onClick={requestOpenModal}
          >
            Ver tudo
          </Button>
        </S.ButtonContainer>
        <PaymentHistory
          isOpen={openModal}
          payments={props.paymentHistory}
          onRequestClose={requestCloseModal}
          title="Todos os Pagamentos"
        />
      </S.Container>
    </>
  );
};

export default PaymentHistoryCard;
