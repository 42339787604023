import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 35px;

  ul {
    display: flex;
    flex-direction: row;

    ${mq.custom(
      css`
        flex-direction: column;
        width: 100%;
        gap: 18px;
      `,
      { minWidth: 0, maxWidth: 1010 },
    )}

    li {
      margin: 0 20px 0 0;
    }
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonSection = styled.div`
  a {
    height: 72px;
    display: block;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 28px;
    text-decoration: none;
    color: #636e6f;
    transition: background 0.5s, color 0.5s, height 0.5s;

    &:hover {
      background: #6d216c;
      color: #fff;
      height: 78px;

      .divLine {
        display: block;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .divLine {
      display: none;
      margin-top: 6px;
      text-align: center;
    }

    svg {
      margin-right: 12px;
    }
  }

  .active-link {
    background: #6d216c;
    color: #ffffff;
    height: 78px;
    .divLine {
      display: block;
    }

    svg {
      path {
        fill: white;
      }
    }
  }

  button {
    height: 72px;
    display: block;
    background: #fff;
    border: 0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 28px;
    text-decoration: none;
    color: #dadee5;
    transition: background 0.5s, color 0.5s, height 0.5s;

    &:hover {
      background: #fff;
      color: #dadee5;
    }

    svg {
      margin-right: 12px;
      path {
        fill: #dadee5;
      }
    }
  }
`;
