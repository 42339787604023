import styled, { css } from 'styled-components';
import mq from '../../../styles/mq';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey2};
  margin-bottom: 77px;
`;

export const ContentWrapper = styled.div`
  padding: 23px 48px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  gap: 18px;
  margin-bottom: 40px;

  ${mq.custom(
    css`
      display: flex;
      flex-direction: column;
    `,
    { minWidth: 0, maxWidth: 1010 },
  )}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
