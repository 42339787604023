/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/search-right.svg';
import Button from '../Button';
import Selector from './components/selector';
import * as S from './styles';
import { useUsers } from '../../services/hooks/useUsers';

interface PropsFilter {
  setPopUpState?: any;
  isOpen?: boolean;
  handleNewValue?: any;
  filterTable?: boolean;
  userType?: string;
}

const PopUpFindName = React.forwardRef(
  (
    {
      setPopUpState,
      userType,
      isOpen = false,
      filterTable = false,
      handleNewValue,
    }: PropsFilter,
    ref: any,
  ) => {
    const [formName, setFormName] = useState(null);
    const [searchData, setSearchData] = useState('');
    const popUpRef = useRef(null);

    const { data: users } = useUsers();

    function handleValue() {
      if (ref && !handleNewValue) {
        ref.value = formName;
      } else if (handleNewValue) {
        handleNewValue(formName);
        setPopUpState(false);
        setSearchData('');
      }
      return null;
    }

    const handleClick = (e: MouseEvent) => {
      if ((popUpRef.current! as any).contains(e.target)) {
        return;
      }
      setPopUpState(false);
      setSearchData('');
    };
    // useEffect(() => {
    //   if (!user) getUser();
    // }, []);

    // useEffect(() => {
    //   if (user && !userType) {
    //     getUser();
    //     setUserList(user);
    //   } else if (user && userType) {
    //     getUser();
    //     const filteredList = user?.filter(e => e.accountType === userType);
    //     setUserList(filteredList);
    //   }
    // }, [userList]);

    useEffect(() => {
      if (isOpen) {
        document.addEventListener('mousedown', handleClick);
      } else {
        document.removeEventListener('mousedown', handleClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, [isOpen]);

    return (
      <S.MainContainer>
        <S.PopUp ref={popUpRef} formProp={filterTable}>
          <div className="search-bar">
            <input
              type="text"
              value={formName?.label}
              onChange={e => {
                setSearchData(e.target.value.toLocaleLowerCase());
                setFormName(e.target.value);
              }}
            />
            <SearchIcon />
          </div>

          <div className="names-list">
            <ul>
              {users
                ?.filter(e => e?.name?.toLocaleLowerCase().includes(searchData))
                .map(e => (
                  <li>
                    <Selector
                      items={e}
                      setNewValue={setFormName}
                      formName={formName}
                    />
                  </li>
                ))}
            </ul>
          </div>

          <div className="buttons-section-popup">
            <Button onClick={handleValue}>
              {filterTable ? 'Filtrar' : 'Selecionar'}
            </Button>

            <Button onClick={() => setPopUpState(false)} ghost>
              Cancelar
            </Button>
          </div>
        </S.PopUp>
      </S.MainContainer>
    );
  },
);

export default PopUpFindName;
