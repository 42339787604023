import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ISubscription, Permissions } from '../../models/user';
import Button from '../../components/Button';
import SimpleCard from '../../components/SimpleCard';
import Switch from '../../components/Switch';
import * as S from './styles';
import DataTable from '../../components/DataTable';
import api from '../../services/api';

export interface PermissionsProps extends ISubscription {
  annualValue?: string;
  monthlyValue?: string;
  accessType?: string;
}

const theaders = [
  {
    id: 0,
    title: 'Função',
  },
  {
    id: 1,
    title: 'Sem Registro',
  },
  {
    id: 2,
    title: 'Pesquisador gratuito',
  },
  {
    id: 3,
    title: 'Pesquisador Assinante',
  },
  {
    id: 4,
    title: 'Assintante Autor',
  },
  {
    id: 5,
    title: 'Assintante Periódico',
  },
  {
    id: 6,
    title: 'Assintante PPG',
  },
];

const trows = [
  {
    function: 'Pesquisar Artigos',
    withoutRegister: <Switch />,
  },
];

const translates = {
  ResearcherSubscriber: 'Pesquisador Assinante',
  ResearcherFree: 'Pesquisador Gratuito',
  PPGSubscriber: 'PPG Assinante',
  NoRegister: 'Sem Registro',
  JournalSubscriber: 'Periódico Assinante',
  CaciqAdmin: 'Administrador CACIQ',
  AuthorSubscriber: 'Autor Assinante',
};

const PermissionsSettigs: React.FC<PermissionsProps> = props => {
  const {
    data: plans,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    'plans',
    async () => {
      const response = await api.get('/plans');
      const data = await response.data;

      return data;
    },
    {
      initialData: [],
    },
  );

  function compare(a, b) {
    if (a.name > b.name) return -1;
    return 0;
  }
  plans.sort(compare);
  const refetchItems = () => {
    refetch();
  };

  const tableColumns = [
    {
      title: 'Tipo de usuário',
      field: 'plan',
      cellStyle: {
        color: '#A5ADBA',
      },
      defaultSort: 'asc',
      defaultGroupSort: 'asc',
      render: rowData => translates[rowData.plan],
    },
    {
      title: 'Pesquisar Artigos',
      field: 'Search_Article',

      render: (rowData: any) => (
        <Switch
          checked={rowData.Search_Article}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Search_Article"
        />
      ),
    },
    {
      title: 'Pesquisar Autores',
      field: 'Search_Author',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Search_Author}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Search_Author"
        />
      ),
    },
    {
      title: 'Pesquisar Periódicos',
      field: 'Search_Journal',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Search_Journal}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Search_Journal"
        />
      ),
    },
    {
      title: 'Pesquisar PPGs',
      field: 'Search_PPG',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Search_PPG}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Search_PPG"
        />
      ),
    },
    {
      title: 'Pesquisar Índices',
      field: 'Search_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Search_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Search_Index"
        />
      ),
    },
    {
      title: 'Resultado: Índices Caciq',
      field: 'Result_Caciq_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Caciq_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Caciq_Index"
        />
      ),
    },
    {
      title: 'Resultado: Índices Caciq-h4',
      field: 'Result_Caciq_H4_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Caciq_H4_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Caciq_H4_Index"
        />
      ),
    },
    {
      title: 'Resultado: Google Schoolar-h',
      field: 'Result_Scholar_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Scholar_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Scholar_Index"
        />
      ),
    },
    {
      title: 'Resultado: Google Schoolar-i10',
      field: 'Result_Scholar_H4_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Scholar_H4_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Scholar_H4_Index"
        />
      ),
    },
    {
      title: 'Resultado: Scimago',
      field: 'Result_Scimago_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Scimago_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Scimago_Index"
        />
      ),
    },
    {
      title: 'Resultado: Qualis',
      field: 'Result_Qualis_Index',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Qualis_Index}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Qualis_Index"
        />
      ),
    },
    {
      title: 'Resultado: Palavras-chave',
      field: 'Result_Keywords',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Keywords}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Keywords"
        />
      ),
    },
    {
      title: 'Resultado: Citações',
      field: 'Result_Citations',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Citations}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Citations"
        />
      ),
    },
    {
      title: 'Resultado: Acesso a página interna de resultado',
      field: 'Result_Internal_Result',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Result_Internal_Result}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Result_Internal_Result"
        />
      ),
    },
    {
      title: 'Página de Perfil',
      field: 'Profile',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Profile}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Profile"
        />
      ),
    },
    /* Permite ao usuário "requisitar" aquele perfil e atrelá-lo à sua conta Caciq */
    {
      title: 'Vincular Perfil na Página de resultado',
      field: 'Profile_Attach_Result',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Profile_Attach_Result}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Profile_Attach_Result"
        />
      ),
    },
    {
      title: 'Anexar Arquivos',
      field: 'Attach_Files',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Attach_Files}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Attach_Files"
        />
      ),
    },
    /* Permite ao usuário (ex atual: PPG) vincular contas (ex: autores) ao seu perfil */
    {
      title: 'Vincular conta ao perfil',
      field: 'Sync_Profile_Account',
      render: (rowData: any) => (
        <Switch
          checked={rowData.Sync_Profile_Account}
          id={rowData.id}
          refetch={refetchItems}
          permissions={rowData?.permissions}
          type="Sync_Profile_Account"
        />
      ),
    },
  ];

  const tableData = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return plans.map(plan => {
      const currentPlan = {
        plan: plan.name,
        id: plan.id,
        permissions: plan?.permissions,
        Search_Article: false,
        Search_Author: false,
        Search_Journal: false,
        Search_PPG: false,
        Search_Index: false,
        Result_Caciq_Index: false,
        Result_Caciq_H4_Index: false,
        Result_Scholar_Index: false,
        Result_Scholar_H4_Index: false,
        Result_Scimago_Index: false,
        Result_Qualis_Index: false,
        Result_Keywords: false,
        Result_Citations: false,
        Result_Internal_Result: false,
        Profile: false,
        Profile_Attach_Result: false,
        Attach_Files: false,
        Sync_Profile_Account: false,
      };
      Object.keys(currentPlan).forEach(key => {
        if (plan?.permissions?.includes(key)) {
          currentPlan[key] = true;
        }
      });
      return currentPlan;
    });
  }, [plans]);

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <SimpleCard
            title="Tempo de Acesso Premium para novos usuários"
            value="0 dia(s)"
          />
          <DataTable
            tableTitle="Permissões Usuário"
            columns={tableColumns}
            data={tableData}
            options={{
              filtering: false,
              pageSize: 7,
              sorting: false,
            }}
            isLoading={isFetching}
            style={{ width: '80vw' }}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default PermissionsSettigs;
