import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUsers } from '../../services/hooks/useUsers';
import LoadingScreen from '../../components/LoadingScreen';

export default function RedirectPage() {
  const history = useHistory();
  const { id } = useParams<any>();
  const { data } = useUsers();

  const currentUser = data?.filter(user => user.id === id);

  let accountType = currentUser?.length > 0 ? currentUser[0]?.accountType : '';

  useEffect(() => {
    if (accountType === 'researcher') accountType = 'pesquisadores';
    if (accountType === 'author') accountType = 'autores';
    if (accountType === 'ppg') accountType = 'ppgs';
    if (accountType === 'journal') accountType = 'revista';
    if (currentUser.length === 0) {
      setTimeout(() => {
        history.push('/');
        toast.info(
          'Desculpe não foi possível completar sua busca, tente novamente mais tarde',
        );
      }, 6000);
    } else {
      setTimeout(() => {
        history.push(`/usuarios/${accountType}/sobre/${id}`);
      }, 1000);
    }
  }, [currentUser, accountType]);

  return <LoadingScreen />;
}
