export const navItems = [
  {
    id: 0,
    title: 'Visão geral',
    href: '/',
  },
  {
    id: 0,
    title: 'Textos',
    href: '/textos',
  },
  {
    id: 0,
    title: 'Usuários',
    href: '/usuarios/pesquisadores',
  },
  {
    id: 0,
    title: 'Assinaturas',
    href: '/assinaturas',
  },
  {
    id: 0,
    title: 'Permissões',
    href: '/permissoes',
  },
];
