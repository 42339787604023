import styled, { css } from 'styled-components';
import { GrowthCardProps } from '.';
import mq from '../../styles/mq';

export const Container = styled.div<GrowthCardProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-grow: 1;
  flex-basis: 253px;

  min-height: 100px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: flex-end;
  width: 100%;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
  text-align: end;
`;

export const Value = styled.h3`
  display: flex;
  font-size: 1.875rem;
`;

export const GrowthWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Growth = styled.p`
  display: flex;
  color: ${({ theme }) => theme.colors.grey2};
  font-size: 1rem;
  margin-left: 5px;
`;
