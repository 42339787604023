import React, { useEffect } from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { navItems } from './navItens';
import { ReactComponent as Logo } from '../../assets/caciq-logo-white.svg';

import * as S from './styles';

interface SidebarProps {
  hasNotification?: boolean;
}

const Sidebar: React.FC<SidebarProps> = props => {
  return (
    <>
      <S.SidebarContainer>
        <S.LogoWrapper>
          <Logo />
        </S.LogoWrapper>
        <S.NavWrapper>
          <ul>
            {navItems.map(item => (
              <li key={item.id}>
                <NavLink
                  activeClassName="is-active"
                  exact
                  to={item.href}
                  isActive={(match, location) => {
                    switch (item.title) {
                      case 'Visão geral':
                        return location.pathname === '/';
                      case 'Textos':
                        return location.pathname.includes('/textos');
                      case 'Assinaturas':
                        return location.pathname.includes('/assinatura');
                      case 'Permissões':
                        return location.pathname.includes('/permissoes');
                      case 'Usuários':
                        return location.pathname.includes('/usuario');
                      default:
                        return false;
                    }
                  }}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </S.NavWrapper>
        <S.BottomWrapper>
          <h6>Plataforma Digital de Logística Científica</h6>
        </S.BottomWrapper>
      </S.SidebarContainer>
    </>
  );
};

export default Sidebar;
