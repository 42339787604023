import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import Routes from './routes/Routes';
import GlobalStyle, { theme } from './styles/global';
import { AuthProvider } from './hooks/auth';
import { queryClient } from './services/queryClient';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ToastContainer />

            <Routes />
            <GlobalStyle />
          </Router>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
