import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import Modal from 'react-modal';
import CsvDownload from 'react-json-to-csv';
import * as S from './styles';
import api from '../../services/api';
import Button from '../Button';
import { ReactComponent as Download } from '../../assets/download.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import FetchingAnimation from '../FetchingAnimation';

export interface PaymentHistoryProps {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  payments?: any;
  notificationContent?: {
    type?: string;
    action?: string;
    text?: string;
    id?: string;
    read?: boolean;
    onClick?: () => void;
  }[];
}

const PaymentHistory: React.FC<PaymentHistoryProps> = ({ ...props }) => {
  const parseStripePrice = (price: number) => {
    if (!price) return 0;
    const value = price.toString();
    const inteiro = value.slice(0, -2);
    const decimais = value.slice(-2);
    const final = Number(`${inteiro}.${decimais}`);
    return final;
  };

  return (
    <>
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: 'fit-content',
            maxWidth: '658px',
            width: '100%',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <S.StyledPopUp>
          <S.TitleAlert>{props.title}</S.TitleAlert>
          <div className="tableLike">
            <div className="tableHead">
              <p>Data</p>
              <p>Valor</p>
              <p>PDF</p>
            </div>

            {props.payments?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="tableBody">
                <p>
                  {format(
                    parseISO(new Date(item?.created).getUTCDate().toString()),
                    'dd/MM/yyyy',
                  )}
                </p>
                <p>{parseStripePrice(item?.amount_paid)}</p>

                <CsvDownload filename="extrato.csv" data={[item]}>
                  <Download style={{ fill: '#6D216C' }} />
                </CsvDownload>
              </div>
            ))}
          </div>
          {props?.payments?.length < 0 && (
            <div className="noNotifications">Sem histórico</div>
          )}
        </S.StyledPopUp>
      </Modal>
    </>
  );
};

export default PaymentHistory;
