/* eslint-disable no-new */
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ISubscription, IUser } from '../../../models/user';
import Button from '../../../components/Button';

import DetailsSection from '../../../components/DetailsSection';
import SimpleCard from '../../../components/SimpleCard';
import SubscriptionsCard from '../../../components/SubscriptionsCard';
import * as S from './styles';
import { useSubscriptionDetails } from '../../../services/hooks/useSubscriptionDetails';
import DataTable from '../../../components/DataTable';
import AlertModal from '../../../components/AlertModal';
import EditModal from '../../../components/EditModal';
import api from '../../../services/api';
import EditPriceModal from '../components/EditPriceModal';
import NewUsersModal from '../components/NewUsersModal';

export interface SubscriptionsDetailsProps extends ISubscription {
  name: string;
  basicRole: string;
  password: string;
  email: string;
  annualValue?: string;
  monthlyValue?: string;
  accessType?: string;
  shortCode?: string;
}

const SubscriptionsDetails: React.FC<SubscriptionsDetailsProps> = props => {
  const { id } = useParams<any>();

  const translation = {
    researcher: 'Pesquisador',
    author: 'Autor',
    ppg: 'PPG',
    journal: 'Periódico',
  };

  const {
    data: subscription,
    isLoading,
    isFetching,
  } = useSubscriptionDetails(id);

  console.log(subscription);

  const [isSubscriptionValueModalOpen, setSubscriptionValueModalOpen] =
    useState(false);
  function handleOpenSubscriptionValueModal() {
    setSubscriptionValueModalOpen(true);
  }
  function handleCloseSubscriptionValueModal() {
    setSubscriptionValueModalOpen(false);
    setSubscriptionEditable(true);
  }

  const [
    isValueEditionConfirmationModalOpen,
    setValueEditionConfirmationModalOpen,
  ] = useState(false);
  function handleSaveValueEditionModal() {
    setSubscriptionValueModalOpen(false);
    setValueEditionConfirmationModalOpen(true);
  }
  function handleCloseValueEditionConfirmationModal() {
    setValueEditionConfirmationModalOpen(false);
  }

  const [isSubscriptionEditable, setSubscriptionEditable] = useState(true);
  function handleMakeEditable() {
    setSubscriptionEditable(false);
  }

  const [isAlertConfirmationModalOpen, setAlertConfirmationModalOpen] =
    useState(false);
  function handleOpenAlertConfirmationModal() {
    setAlertConfirmationModalOpen(true);
    setValueEditionConfirmationModalOpen(false);
  }
  function handleCloseAlertConfirmationModal() {
    setAlertConfirmationModalOpen(false);
  }

  const [isNewUsersModalOpen, setNewUsersModalOpen] = useState(false);
  function handleOpenNewUsersModal() {
    setNewUsersModalOpen(true);
  }
  function handleCloseNewUsersModal() {
    setNewUsersModalOpen(false);
  }

  const inputs = [
    {
      id: '1',
      defaultValue: subscription?.price || 'R$ 000,00',
    },
  ];

  const subscriptionItems = [
    {
      id: 0,
      title: 'Sobre',
      href: `/assinaturas/sobre/${id}`,
    },
    {
      id: 1,
      title: 'Cobrança',
      href: `/cobranca/${id}`,
    },
  ];

  const tableColumns = [
    {
      title: 'Nome usuário',
      field: 'userName',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '40%', color: '#A5ADBA' },
    },
    {
      title: 'E-mail',
      field: 'email',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '35%', color: '#A5ADBA' },
    },

    {
      title: <Button onClick={handleOpenNewUsersModal}>+ Adicionar</Button>,
      cellStyle: { width: '16%', color: '#A5ADBA' },
    },
  ];

  let allUsers = subscription?.allowed_users.concat(subscription?.users);

  allUsers = allUsers?.reduce((unique, o) => {
    if (!unique.some(obj => obj.email === o.email)) unique.push(o);
    return unique;
  }, []);

  const tableData = useMemo(() => {
    return allUsers?.map(user => {
      return {
        userName: user.name,
        email: user.email,
      };
    });
  }, [subscription]);

  const inputsNewSubscription = [
    {
      id: '1',
      label: 'Nome Plano',
      name: 'planName',
      placeholder: 'Nome plano',
    },
    {
      id: '2',
      label: 'Tipo de Acesso',
      name: 'accessType',
      placeholder: 'Pesquisador/Autor/Periódico/PPG',
    },
    {
      id: '3',
      label: 'Anexar Planilha de contatos',
      name: 'accessType',
      placeholder: 'Anexar aquivo',
    },
  ];

  const [isNewUsersConfirmationModalOpen, setNewUsersConfirmationModalOpen] =
    useState(false);
  function handleConfirmNewUsers() {
    setNewUsersModalOpen(false);
    setNewUsersConfirmationModalOpen(true);
  }
  function handleCloseConfirmNewUsers() {
    setNewUsersConfirmationModalOpen(false);
  }

  const parseStripePrice = (price: number) => {
    if (!price) return 0;
    const value = price.toString();
    const inteiro = value.slice(0, -2);
    const decimais = value.slice(-2);
    const final = Number(`${inteiro}.${decimais}`);
    return final;
  };

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <DetailsSection paths={subscriptionItems} />
          <S.RowWrapper>
            {/* TODO: lógica dos botões de deletar e adicionar */}
            <DataTable
              tableTitle="Lista de usuários"
              columns={tableColumns}
              data={tableData}
              isLoading={isFetching}
            />
            <S.ColumnWrapper>
              <SubscriptionsCard
                direction="column"
                title={`Valor a ser cobrado ${
                  subscription?.recurrence === 'monthly'
                    ? 'mensalmente'
                    : 'anualmente'
                }`}
                isIgnoringPayment={subscription?.ignorePayment}
                annualValue={parseStripePrice(subscription?.price)}
                annualTitle=" "
                hideMonth
                onClick={handleOpenSubscriptionValueModal}
              />
              <SimpleCard
                title="Tipo de Acesso"
                hideEditButton
                value={translation[subscription?.accessType]}
              />
              <SimpleCard
                title="Código do Plano"
                hideEditButton
                value={subscription?.shortCode}
              />
              <SimpleCard
                title="Quantidade de Usuários"
                hideEditButton
                value={allUsers?.length}
              />
            </S.ColumnWrapper>
          </S.RowWrapper>
          <EditPriceModal
            isOpen={isSubscriptionValueModalOpen}
            onRequestClose={handleCloseSubscriptionValueModal}
            subscriptionId={subscription?.id}
            alertContent={{
              title: 'Valor Assinatura',
              ghostButtonText: !isSubscriptionEditable ? null : 'Voltar',
              onClickGhost: handleCloseSubscriptionValueModal,
              dangerButtonText: !isSubscriptionEditable ? 'Cancelar' : null,
              onClickDanger: handleCloseSubscriptionValueModal,
              defaultButtonText: 'Salvar',
              onClickDefault: handleMakeEditable,
            }}
            inputs={inputs}
          />
          <AlertModal
            isOpen={isValueEditionConfirmationModalOpen}
            onRequestClose={handleCloseValueEditionConfirmationModal}
            alertContent={{
              title: 'Confirmar Mudança de Valor',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue risus sagittis neque condimentum bibendum viverra. Tellus quisque sed enim faucibus amet, eu, sed vulputate. Leo tellus enim mi.',
              dangerButtonText: 'Cancelar',
              onClickDanger: handleCloseValueEditionConfirmationModal,
              defaultButtonText: 'Confirmar',
              onClickDefault: handleOpenAlertConfirmationModal,
            }}
            reverseButtonRow
          />
          <AlertModal
            isOpen={isAlertConfirmationModalOpen}
            onRequestClose={handleCloseAlertConfirmationModal}
            alertContent={{
              title: 'Valor Alterado',
              defaultButtonText: 'Voltar',
              onClickDefault: handleCloseAlertConfirmationModal,
            }}
          />

          <NewUsersModal
            isOpen={isNewUsersModalOpen}
            onRequestClose={handleCloseNewUsersModal}
            subscriptionId={subscription?.id}
            subscriptions={subscription?.users}
            alertContent={{
              title: 'Adicionar Usuários',
              ghostButtonText: 'Voltar',
              onClickGhost: handleCloseNewUsersModal,
              defaultButtonText: 'Adicionar',
              onClickDefault: handleConfirmNewUsers,
            }}
            inputs={inputsNewSubscription}
          />
          <AlertModal
            isOpen={isNewUsersConfirmationModalOpen}
            onRequestClose={handleCloseConfirmNewUsers}
            alertContent={{
              title: 'Usuários Adicionados',
              defaultButtonText: 'Voltar',
              onClickDefault: handleCloseConfirmNewUsers,
            }}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default SubscriptionsDetails;
function setTableData(arg0: any[]) {
  throw new Error('Function not implemented.');
}
