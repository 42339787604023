import Modal from 'react-modal';
import React, { useState } from 'react';
import * as S from './styles';

import Button from '../Button';
import closeImg from '../../assets/close.svg';
import { ReactComponent as DocumentIcon } from '../../assets/document-icon.svg';

export interface AlertModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  reverseButtonRow?: boolean;
  alertContent?: {
    title?: string;
    document?: any;
    text?: string;
    planCode?: string;
    defaultButtonText?: string;
    dangerButtonText?: string;
    onClickDefault?: () => void;
    onClickDanger?: () => void;
    onClickDoc?: () => void;
  };
}

const AlertModal: React.FC<AlertModalProps> = ({ ...props }) => {
  return (
    <>
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            top: '40px',
            height: 'fit-content',
            margin: 'auto',
            width: '100%',
            maxWidth: '408px',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <button
          type="button"
          onClick={props.onRequestClose}
          style={{
            top: 20,
            right: 20,
            position: 'absolute',
            background: 'none',
            border: 'none',
            zIndex: 9999,
          }}
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>
        <S.ModalContent>
          <>
            <S.StyledPopUp {...props}>
              <S.TitleAlert>{props.alertContent.title}</S.TitleAlert>
              {props.alertContent.document && (
                <Button
                  link
                  color="blueMidtone"
                  onClick={props.alertContent.onClickDoc}
                >
                  <DocumentIcon /> Documento enviado
                </Button>
              )}

              <S.TextAlert>{props.alertContent.text}</S.TextAlert>
              <h2 style={{ marginBottom: '20px' }}>
                {props.alertContent.planCode}
              </h2>

              <S.ButtonWrapper {...props}>
                {props.alertContent.defaultButtonText && (
                  <Button
                    color="blueMidtone"
                    shadow={false}
                    onClick={props.alertContent.onClickDefault}
                    size="small"
                  >
                    {props.alertContent.defaultButtonText}
                  </Button>
                )}

                {props.alertContent.dangerButtonText && (
                  <Button
                    color="redMidtone"
                    shadow={false}
                    onClick={props.alertContent.onClickDanger}
                    size="small"
                  >
                    {props.alertContent.dangerButtonText}
                  </Button>
                )}
              </S.ButtonWrapper>
            </S.StyledPopUp>
          </>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default AlertModal;
