import styled, { css } from 'styled-components';
import { PlanCardProps } from '.';
import mq from '../../styles/mq';

export const Container = styled.div<PlanCardProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-width: 340px;
  height: 110px;
  padding: 20px;

  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
  text-align: start;
  width: 200px;
`;

export const Value = styled.h3`
  display: flex;
  font-size: 1.875rem;

  color: ${({ theme }) => theme.colors.blueMidtone};
`;
