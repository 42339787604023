import React from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as AuthorIcon } from '../../assets/author-icon.svg';
import { ReactComponent as Search } from '../../assets/search-right.svg';
import { ReactComponent as JournalIcon } from '../../assets/journalIcon.svg';
import { ReactComponent as PpgIcon } from '../../assets/ppgIcon.svg';
import { ButtonSection, Nav, ContainerRow } from './styles';

import Line from '../../assets/line-btn.png';

const Section = () => {
  const history = useHistory();

  const sectionItems = [
    {
      id: 0,
      title: 'Pesquisadores',
      href: '/usuarios/pesquisadores',
      src: (
        <Search style={{ fill: '#6d216c', width: '24px', height: '24px' }} />
      ),
    },
    {
      id: 1,
      title: 'Autores',
      href: '/usuarios/autores',
      src: <AuthorIcon />,
    },
    {
      id: 2,
      title: 'Revistas',
      href: '/usuarios/revistas',
      src: <JournalIcon />,
    },
    {
      id: 3,
      title: 'Programas de Pós-graduação',
      href: '/usuarios/ppgs',
      src: <PpgIcon />,
    },
  ];

  return (
    <>
      <Nav>
        <ul>
          {sectionItems.map(itens => (
            <li key={itens.id}>
              <ButtonSection>
                <NavLink to={itens.href} activeClassName="active-link" exact>
                  <ContainerRow>
                    {itens.src}
                    <span>{itens.title}</span>
                  </ContainerRow>
                  <img src={Line} alt="" className="divLine" />
                </NavLink>
              </ButtonSection>
            </li>
          ))}
        </ul>
      </Nav>
    </>
  );
};

export default Section;
