import React, { useMemo, useState, useEffect } from 'react';
import Button from '../../../components/Button';
import DataTable from '../../../components/DataTable';
import Section from '../../../components/Section';
import ContentTag from '../../../components/ContentTag';
import TotalCountCard from '../../../components/TotalCountCard';
import { useUsers } from '../../../services/hooks/useUsers';
import ModalContent from '../../../components/ModalContent';
import { useUsersElastic } from '../../../services/hooks/useUsersElastic';
import * as S from './styles';

const PPGs: React.FC = () => {
  const [allPPG, setAllPPG] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalTagList, setModalTagList] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const { data: ppgs, isFetching } = useUsers('ppg');

  const { data: ppgElastic } = useUsersElastic('ppgs', currentPageNumber);

  const filterByVerification = ppgs.filter(e => e?.isVerified === true);
  const filterByVerificationPending = ppgs?.filter(
    e => e?.verifications[0]?.status === 'waiting',
  );
  const filterBySubscriptions = ppgs.filter(
    e => e?.subscriptions?.enabled === true,
  );

  function requestOpenModal(tags) {
    setModalTagList(tags);
    setOpenModal(true);
  }
  function requestCloseModal() {
    setOpenModal(false);
  }

  useEffect(() => {
    if (currentPageNumber > 0) setAllPPG(ppgElastic.users);
    else setAllPPG([...ppgs, ...ppgElastic.users]);
  }, [currentPageNumber, ppgElastic.users]);

  const tableColumns = [
    {
      title: 'PPGs',
      field: 'ppgs',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '238px', color: '#A5ADBA' },
    },

    // {
    //   title: 'Acessos',
    //   field: 'accesses',
    //   emptyValue: '-',
    //   type: 'numeric',

    //   filterPlaceholder: 'Filtrar',
    //   cellStyle: { width: '145px', color: '#A5ADBA' },
    // },
    {
      title: 'Índice',
      filterPlaceholder: 'Filtrar',
      field: 'index',
      emptyValue: '-',
      cellStyle: { width: '100px' },
    },
    {
      title: 'Professores vinculados',
      field: 'linkedAuthors',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { color: '#A5ADBA' },
    },

    {
      title: 'Status',
      field: 'status',
      defaultSort: 'asc',
      emptyValue: '-',
    },
    {
      title: 'Ação',
      field: 'action',
      filtering: false,
      sorting: false,
      searchable: false,
    },
  ];

  const tableData = useMemo(() => {
    return allPPG.map(ppg => {
      return {
        ppgs: ppg?._source?.name || ppg.name,
        // accesses: ppg.accesses,
        index: (
          <>
            {ppg?._source?.indexes?.['IC-P'] ? (
              <strong className="table-index">
                IC-P <span>{Number(ppg?._source?.indexes?.['IC-P'])}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
            {ppg?._source?.indexes?.['IC-H4-P'] ? (
              <strong className="table-index">
                IC-H4-P{' '}
                <span>{Number(ppg?._source?.indexes?.['IC-H4-P'])}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
          </>
        ),
        /* TODO: fazer o calculo com o filtro de ano */
        /* TODO: verificar com Edu sobre os autores vinculados */
        linkedAuthors: ppg?._source?.authorsRel.length,

        status: ppg?.verifications?.length ? (
          ppg?.verifications[0]?.status === 'approved' ? (
            <span className="table-green-tag">Verificado</span>
          ) : ppg?.verifications[0]?.status === 'waiting' ? (
            <span className="table-red-tag">A verificar</span>
          ) : (
            <span className="table-green-outline-tag">Sem registro</span>
          )
        ) : (
          <span className="table-green-outline-tag">Sem registro</span>
        ),
        action: (
          <Button
            link
            href={`/usuarios/ppgs/sobre/${ppg?.id || ppg?._source?.id}`}
          >
            Ver mais
          </Button>
        ),
      };
    });
  }, [allPPG, currentPageNumber]);

  console.log(ppgs);

  return (
    // TODO: linkar o valor dos TotalCountCard
    <>
      <ModalContent
        isOpen={openModal}
        onRequestClose={requestCloseModal}
        modalType="tag"
        type="ppg"
        tags={modalTagList || ['-']}
      />
      <S.MainContainer>
        <S.ContentWrapper>
          <Section />
          <S.RowWrapper>
            <TotalCountCard
              title="Total de PPGs na Plataforma"
              value={(ppgs.length + ppgElastic.total).toLocaleString()}
            />
            <TotalCountCard
              title="Total de PPGs Assinantes"
              value={filterBySubscriptions.length}
            />
            <TotalCountCard
              title="Total de PPGs Verificados"
              value={filterByVerification.length}
              hasPending
              pendingValue={filterByVerificationPending.length}
            />
          </S.RowWrapper>
          <DataTable
            tableTitle="Programas de Pós-Graduação"
            columns={tableColumns}
            totalCount={ppgs.length + ppgElastic.total}
            onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
            page={currentPageNumber}
            data={tableData}
            isLoading={isFetching}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default PPGs;
