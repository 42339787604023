import styled, { css } from 'styled-components';
import { PersonalDataProps } from '.';
import mq from '../../styles/mq';

export const VeriFyedAuthorContainer = styled.div<PersonalDataProps>`
  max-width: ${props => (props.small ? '340px' : '596px')};
  width: 100%;

  p {
    overflow-wrap: break-word;
    a {
      font-family: ${({ theme }) => theme.fonts.mulish.p};
      color: ${({ theme }) => theme.colors.grey2};
      &:hover {
        color: ${({ theme }) => theme.colors.purpleDefault};
      }
    }
  }

  ${mq.tablet(css`
    margin-bottom: 20px;
  `)}

  h6 {
    color: ${({ theme }) => theme.colors.blueMidtone};

    margin-top: 40px;
  }

  div {
    border-bottom: 1.5px solid #dadee5;
    padding: 13px 22px;
    color: ${({ theme }) => theme.colors.grey2};
  }

  section {
    margin: auto 0;
  }
`;
