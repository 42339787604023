import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  width: 100%;
  height: 208px;
  padding: 15px 20px;
  margin-top: 18px;

  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1.5px solid #dadee5;

  width: 100%;
  height: 50px;

  .first-col {
    max-width: 361px;
    width: 100%;
    margin-right: 20px;
  }

  .second-col {
    max-width: 294px;
    width: 100%;
    margin-right: 20px;
  }

  .last-col,
  .empty-col {
    width: 190px;

    margin-right: 20px;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 21px;
  color: ${({ theme }) => theme.colors.grey4};

  .first-col {
    max-width: 361px;
    width: 100%;
    margin-right: 20px;
  }

  .second-col {
    max-width: 294px;
    width: 100%;
    margin-right: 20px;
  }

  .last-col,
  .empty-col {
    width: 190px;

    margin-right: 20px;

    button {
      text-align: right;
      margin-left: auto;
      justify-content: flex-end;
    }
  }
`;
