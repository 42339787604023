import { useQuery } from 'react-query';
import api from '../api';

async function getUser(accountType: string) {
  const { data }: any = await api.get('users');

  const filterSearch = data.filter(
    filter => filter?.accountType === accountType,
  );

  if (accountType === '') return data;
  return filterSearch;
}

export function useUsers(accountType = '') {
  return useQuery(['users', accountType], () => getUser(accountType), {
    initialData: [],
  });
}
