import styled, { css } from 'styled-components';

interface FormProp {
  dateProp: boolean;
}

export const StyledForm = styled.div<FormProp>`
  min-width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: ${prop => (prop.dateProp ? 'row' : 'column')};
  align-items: ${prop => (prop.dateProp ? 'center' : 'flex-start')};
  font-size: 12px;
  margin-top: 2px;

  svg {
    fill: ${({ theme }) => theme.colors.blueMidtone};
  }

  .css-319lph-ValueContainer {
    padding: 0 2px;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    border-radius: 10px;
    ${prop => {
      if (prop.dateProp) {
        return css`
          height: 20px;
          min-width: fit-content;
          width: 100%;
          margin-top: 0px;
          background: none;
          border: none;
        `;
      }

      return css`
        height: 45px;
        min-width: fit-content;
        width: 100%;
        margin-top: 12px;
        background: white;
        border: 1.5px solid #dadee5;
      `;
    }}
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  label {
    font-family: 'Mulish';
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #17a1a1;
  }
`;
