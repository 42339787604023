import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { InputSharp } from '@material-ui/icons';
import * as S from './styles';

import Button from '../Button';
import closeImg from '../../assets/close.svg';

export interface EditModalProps {
  isOpen: boolean;
  formResponse?: any;
  formRequest?: any;
  onRequestClose: () => void;
  isPJ?: boolean;
  alertContent?: {
    title?: string;
    attachment?: boolean;
    text?: string;
    defaultButtonText?: string;
    ghostButtonText?: string;
    dangerButtonText?: string;
    onClickDefault?: () => void;
    onClickGhost?: () => void;
    onClickDanger?: () => void;
  };
  inputs?: {
    type?: 'input' | 'select' | 'upload' | 'preview';
    id?: string;
    label?: string;
    name?: string;
    defaultValue?: string;
    isEditable?: boolean;
  }[];
}

interface FormInputsPf {
  pfPriceYearly: string;
  pfPriceMonthly: string;
}
interface FormInputsPj {
  pjPriceYearly: string;
  pjPriceMonthly: string;
}

const EditModal: React.FC<EditModalProps> = ({ ...props }) => {
  const { register, handleSubmit } = useForm<FormInputsPf | FormInputsPj>();

  const onSubmit: SubmitHandler<FormInputsPf | FormInputsPj> = data => {
    props.formResponse({ ...props.formRequest, ...data });

    props.alertContent?.onClickDefault();
  };

  return (
    <>
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            zIndex: 9999,
            top: '40px',
            height: 'fit-content',
            margin: 'auto',
            width: '100%',
            maxWidth: '636px',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <button
          type="button"
          onClick={props.onRequestClose}
          style={{
            top: 20,
            right: 20,
            position: 'absolute',
            background: 'none',
            border: 'none',
            zIndex: 9999,
          }}
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>
        <S.ModalContent>
          <>
            <S.StyledPopUp {...props}>
              <S.TitleAlert>{props.alertContent?.title}</S.TitleAlert>

              <form onSubmit={handleSubmit(onSubmit)}>
                <S.InputWrapper {...props}>
                  <ul>
                    <li>
                      <label htmlFor={props?.inputs[0]?.name}>
                        {props?.inputs[0]?.label}
                      </label>
                      <input
                        disabled={props?.inputs[0]?.isEditable}
                        id={props?.inputs[0]?.name}
                        {...register(
                          props.isPJ ? 'pjPriceYearly' : 'pfPriceYearly',
                        )}
                        defaultValue={props.inputs[0].defaultValue}
                      />
                    </li>
                    <li>
                      <label htmlFor={props?.inputs[1]?.name}>
                        {props?.inputs[1]?.label}
                      </label>
                      <input
                        disabled={props?.inputs[1]?.isEditable}
                        id={props?.inputs[1]?.name}
                        {...register(
                          props.isPJ ? 'pjPriceMonthly' : 'pfPriceMonthly',
                        )}
                        defaultValue={props.inputs[1].defaultValue}
                      />
                    </li>
                  </ul>
                </S.InputWrapper>

                <S.ButtonWrapper {...props}>
                  {props.alertContent?.dangerButtonText && (
                    <Button
                      color="redMidtone"
                      shadow={false}
                      onClick={props.alertContent?.onClickDanger}
                      size="small"
                    >
                      {props.alertContent?.dangerButtonText}
                    </Button>
                  )}

                  {props.alertContent?.ghostButtonText && (
                    <Button
                      color="blueMidtone"
                      shadow={false}
                      onClick={props.alertContent?.onClickGhost}
                      size="small"
                      ghost
                    >
                      {props.alertContent?.ghostButtonText}
                    </Button>
                  )}

                  {props.alertContent?.defaultButtonText && (
                    <Button
                      color="blueMidtone"
                      shadow={false}
                      size="small"
                      typeOf="submit"
                    >
                      {props.alertContent?.defaultButtonText}
                    </Button>
                  )}
                </S.ButtonWrapper>
              </form>
            </S.StyledPopUp>
          </>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default EditModal;
