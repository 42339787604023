import styled from 'styled-components';
import { ContentTagProps } from '.';

export const StyledtTag = styled.a<ContentTagProps>`
  width: fit-content;
  height: 24px;
  max-width: ${props => (props.large ? '294px' : '151px')};

  display: flex;

  padding: 5px 15px;
  border: none;
  /* margin-right: 10px; */

  border-radius: 8px;
  background: #f7f7f7;

  cursor: pointer;
  & + a {
    /* margin-top: 18px; */
  }

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 !important;
    white-space: nowrap;
    /* text-transform: uppercase; */

    display: inline-block;
    white-space: nowrap;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Mulish', sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #636e6f;
  }

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    background: #94f0f0;
    transition: background 0.2s;
  }

  &:active {
    background: #1dcfcf;
    transition: background 0.2s;
    border: 0;
    color: #fff;
  }
`;
