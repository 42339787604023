import styled, { css } from 'styled-components';

import { NotificationModalProps } from '.';
import mq from '../../styles/mq';

export const StyledPopUp = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .noNotifications {
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 0.9rem;
    margin-top: 18px;
  }

  .close-icon {
    path {
      stroke: white;
    }
  }

  .unreadMessage {
    border: 0 !important;
    &::before {
      content: '';
      display: block;
      background: rgba(23, 161, 161, 0.1);
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
      right: -50px;
      z-index: 1;
    }
  }

  .tableLike {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;

    .tableHead,
    .tableBody {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.grey4};
      text-align: start;

      p {
        z-index: 2;
      }
      p:first-child {
        width: 30%;
      }

      p:nth-child(2) {
        width: 50%;
      }

      p:last-child {
        width: 20%;
        text-align: end;
        text-align: -webkit-right;
      }
    }

    .tableHead {
      p {
        color: ${({ theme }) => theme.colors.grey3};
      }
      margin-bottom: 10px;
    }

    .tableBody {
      position: relative;
      padding: 20px 0 10px 0;

      border-top: 1px solid ${({ theme }) => theme.colors.grey6};

      & + .tableBody {
        margin-top: 10px;
      }
    }
  }
`;

export const TitleAlert = styled.h5`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  padding: 10px 20px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.grey2};
`;
