import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  width: 100%;
  max-width: 340px;
  height: 340px;
  padding: 15px 20px;
  margin-left: 18px;

  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const Title = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey2};
  margin-bottom: 16px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey4};
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey4};
  margin-top: 16px;
`;

export const LeftContent = styled.div`
  display: flex;
  flex: 2;
  margin-right: 5px;

  .data-text {
    font-size: 0.75rem;
    font: ${({ theme }) => theme.fonts.mulish.h6};
  }

  img {
    width: 36px;
    height: 36px;
    margin-right: 11px;
    border-radius: 100%;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
    object-fit: cover;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex: 1;

  text-align: center;
  justify-content: center;

  .number-text {
    font: ${({ theme }) => theme.fonts.workSans.p};
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.blueMidtone};
  }

  button {
    background: none;
    border: none;

    svg {
      fill: red;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  height: 46px;
  padding-top: 16px;
  margin-top: 16px;

  border-top: 1px solid ${({ theme }) => theme.colors.grey5};
`;
