import React, { ReactNode } from 'react';
import { ReactComponent as PositiveGrowthIcon } from '../../assets/positive-growth.svg';
import { ReactComponent as NegativeGrowthIcon } from '../../assets/negative-growth.svg';
import AnonymousUserIcon from '../../assets/anonymous-user.svg';

import * as S from './styles';
import SelectInput from '../Select';
import { IUser } from '../../models/user';
import Button from '../Button';

export interface PlanCardProps extends IUser {
  basicRole?: string;
  buttonText?: string;
  buttonClick?: () => void;
}

const PlanCard: React.FC<PlanCardProps> = props => {
  return (
    <>
      <S.Container {...props}>
        <S.DataWrapper>
          <div>
            <S.Title>Tipo de conta</S.Title>
            <h3>{props.basicRole}</h3>
          </div>
          <Button ghost onClick={props.buttonClick}>
            {props.buttonText}
          </Button>
        </S.DataWrapper>
      </S.Container>
    </>
  );
};

export default PlanCard;
