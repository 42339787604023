import React, { useMemo, useState } from 'react';
import DataTable from '../../../components/DataTable';
import Button from '../../../components/Button';
import Section from '../../../components/Section';
import TotalCountCard from '../../../components/TotalCountCard';
import { useUsers } from '../../../services/hooks/useUsers';
import * as S from './styles';

const Researchers: React.FC = () => {
  const { data: users, isFetching } = useUsers('researcher');

  console.log(users);

  const filterBySubscriptions = users.filter(
    e => e?.subscriptions?.enabled === true,
  );
  const filterBySubscriptionFree = users.filter(e => !e?.subscriptions);

  const tableColumns = [
    {
      title: 'Pesquisador',
      field: 'researcherName',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '338px', color: '#A5ADBA' },
    },
    {
      title: 'E-mail',
      field: 'email',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '145px', color: '#A5ADBA' },
    },
    {
      title: 'Assinante',
      field: 'subscriber',
      filterPlaceholder: 'Filtrar',
      emptyValue: '-',
      // render: (rowData) => ()
    },

    {
      title: 'Ação',
      field: 'action',
      filtering: false,
      sorting: false,
      searchable: false,
    },
  ];

  const tableData = useMemo(() => {
    return users?.map(user => {
      return {
        researcherName: user.name,
        email: user.email,
        subscriber: user.subscriptions ? (
          <span className="table-green-tag">Assinante</span>
        ) : (
          <span className="table-purple-tag">Gratuito</span>
        ),

        action: (
          <Button link href={`/usuarios/pesquisadores/sobre/${user.id}`}>
            Ver mais
          </Button>
        ),
      };
    });
  }, [users]);

  return (
    // TODO: linkar o valor dos TotalCountCard
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <Section />
          <S.RowWrapper>
            <TotalCountCard
              title="Total de Pesquisadores Gratuitos"
              value={filterBySubscriptionFree.length}
            />
            <TotalCountCard
              title="Total de Pesquisadores Assinantes"
              value={filterBySubscriptions.length}
            />
          </S.RowWrapper>

          <DataTable
            tableTitle="Pesquisadores"
            columns={tableColumns}
            data={tableData}
            isLoading={isFetching}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default Researchers;
