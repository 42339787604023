/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import { IndexProps } from '.';
import mq from '../../styles/mq';

export const IndexContainer = styled.div<IndexProps>`
  ${props => {
    if (props.caciq) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 114px;
        width: 100%;

        h2 {
          color: ${({ theme }) => theme.colors.blueDefault};
        }

        h6 {
          font-weight: 900;
        }
      `;
    }
    return css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      max-width: 114px;
      width: 100%;

      h2 {
        color: ${({ theme }) => theme.colors.blueDefault};
        font-size: 1rem;
        margin-left: 8px;
      }

      h6 {
        font-weight: 500;
      }
    `;
  }}
`;
