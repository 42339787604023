import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/global';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;
export const MainContainer = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;

interface FilterProps {
  formProp: boolean;
}

export const PopUp = styled.div<FilterProps>`
  width: 100%;
  max-width: ${prop => (prop.formProp ? '360px' : '648px')};
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  position: absolute;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  overflow: hidden;
  animation: 0.4s ${entranceAnim} ease;

  .search-bar {
    width: 100%;
    height: 45px;
    position: relative;
    border-radius: 10px;
    border: 1px solid ${theme.colors.grey6};
    overflow: hidden;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 10px;
      font-size: 16px;
      padding: 4px 10px;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    svg {
      position: absolute;
      pointer-events: none;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      path {
        fill: #3569c1;
      }
    }
  }

  .names-list {
    margin-top: 12px;
    border-radius: 10px;
    border: 1px solid ${theme.colors.grey6};
    padding: 16px;

    ul {
      max-height: 200px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: ${theme.colors.grey8};
        border-radius: 99px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${theme.colors.grey6};
        border-radius: 99px;
      }

      li {
        input {
          margin-right: 10px;
        }

        label {
          ${theme.fonts.workSans.p};
          color: ${theme.colors.grey3};
        }

        & + li {
          margin-top: 12px;
        }
      }
    }
  }

  .buttons-section-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
  }
`;
