import styled from 'styled-components';

import BgDefault from '../../assets/FundoExterno.png';

export const MainContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: url(${BgDefault});
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  padding-top: 136px;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 596px;

  h2 {
    ${({ theme }) => theme.fonts.mulish.h2};
    color: ${({ theme }) => theme.colors.grey2};

    margin-bottom: 42px;
    text-align: center;

    span {
      color: ${({ theme }) => theme.colors.purpleDefault};
    }
  }

  form {
    width: 100%;
    margin-bottom: 92px;

    div {
      input {
        width: 100%;
        height: 45px;
        margin-top: 12px;
        border: 1.5px solid #dadee5;
        border-radius: 10px;
        padding: 16px 4px 16px 12px;
        color: #636e6f;
        font-family: 'Work Sans', sans-serif;
        font-weight: normal;
        font-size: 16px;
        outline: none;

        &::placeholder {
          color: #a9b3c4;
        }

        &:focus {
          border: 1.5px solid #17a1a1;
        }
      }

      &:nth-child(2) {
        margin-top: 21px;
      }

      p {
        ${({ theme }) => theme.fonts.mulish.h6};
        color: ${({ theme }) => theme.colors.redDefault};
        position: relative;
      }
    }

    .forgot-password-section {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;

      input {
        width: 12px !important;
        height: 12px !important;
        margin: 0 6px 0 0 !important;
      }

      a {
        ${({ theme }) => theme.fonts.mulish.h6};
        color: ${({ theme }) => theme.colors.purpleDefault};

        text-decoration: underline;
      }
    }

    label {
      font-family: 'Mulish';
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #17a1a1;
    }

    .buttons-section {
      margin-top: 42px;
      display: flex;
      flex-wrap: wrap;
      padding: 0 46px;
      gap: 21px;

      button {
        /* max-width: 230px; */
        width: 100%;
        flex-grow: 1;
        flex-basis: 140px;

        background: ${({ theme }) => theme.colors.purpleDefault};
        border: 0;
        padding: 16px 30px;
        border-radius: 6px;

        font-weight: 700;
        color: white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
        transition: 0.3s;

        &:hover {
          background: ${({ theme }) => theme.colors.purpleMidtone};

          color: white;
        }
      }

      .orcid-login {
        box-shadow: none;
        background: none;

        color: ${({ theme }) => theme.colors.purpleDefault};
        border: 1px solid ${({ theme }) => theme.colors.purpleDefault};
      }
    }
  }
`;
