import styled, { css } from 'styled-components';
import { ButtonProps } from '.';
import mq from '../../styles/mq';

const sizes = {
  default: '0.875rem',
  small: '0.75rem',
  big: '1.25rem',
};

const handleColor = (color: string) => {
  switch (color) {
    case 'blueMidtone':
      return ({ theme }) => theme.colors.blueMidtone;

    case 'blueDefault':
      return ({ theme }) => theme.colors.blueDefault;

    case 'redMidtone':
      return ({ theme }) => theme.colors.redMidtone;

    default:
      return ({ theme }) => theme.colors.purpleDefault;
  }
};

export const StyledButton = styled.button.attrs<ButtonProps>(({ typeOf }) => ({
  type: typeOf || 'button',
}))<ButtonProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  padding: ${props => (props.size ? '8px 12px' : '12px 18px')};
  box-shadow: ${props =>
    props.shadow ? '0px 2px 15px rgba(0, 0, 0, 0.25)' : '0'};
  border-radius: 6px;

  font-size: ${props => (props.size ? sizes[props.size] : sizes.default)};
  font-weight: bold;
  letter-spacing: 1px;
  transition: 0.2s;

  ${props => {
    if (props.ghost) {
      return css`
        background: transparent;
        color: ${handleColor(props.color)};
        border: 1px solid;

        &:hover {
          background: ${handleColor(props.color)};
          color: white;
        }
      `;
    }
    if (props.link) {
      return css`
        background: transparent;
        color: ${handleColor(props.color)};
        text-decoration: underline;
        border: 0px;
      `;
    }
    return css`
      background: ${handleColor(props.color)};
      color: white;
      border: 0px;

      &:hover {
        filter: brightness(80%);
      }
    `;
  }}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover svg {
    fill: #fff;
  }

  img {
    padding-left: 15px;
  }

  ${mq.tablet(css<ButtonProps>`
    padding: ${props => (props.size ? '6px 10px' : '10px 16px')};
    font-size: 0.85rem;
  `)}

  ${mq.mobile(css<ButtonProps>`
    font-size: 0.75rem;
  `)}
`;
