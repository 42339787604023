/* eslint-disable consistent-return */
import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import DataTable from '../../../components/DataTable';
import Section from '../../../components/Section';
import TotalCountCard from '../../../components/TotalCountCard';
import Button from '../../../components/Button';
import { ReactComponent as PDFIcon } from '../../../assets/pdf-btn.svg';
import * as S from './styles';
import { useUsersElastic } from '../../../services/hooks/useUsersElastic';
import getClient from '../../../services/client/elasticsearch';
import { useUsers } from '../../../services/hooks/useUsers';

const Authors: React.FC = () => {
  const [allAuthors, setAllAuthors] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [totalVerified, setTotalVerified] = useState(0);
  const [handleAnualFilter, setHandleAnualFilter] = useState([]);
  const [triggerSearch, setTriggerSearch] = useState('');
  const [search, setSearch] = useState('');
  const [loadingAnim, setLoadingAnim] = useState(false);

  const { data: authorVerified } = useQuery(
    ['author'],
    async () => {
      const response = await getClient().search({
        index: 'authors',
        size: 1,
        body: {
          query: {
            exists: {
              field: 'userId',
            },
          },
        },
      });
      const totalData: any = await response.hits.total;
      setTotalVerified(totalData.value);
      return totalData;
    },
    {
      initialData: [],
    },
  );

  const { data: authors, isFetching } = useUsers('author');

  const { data: users, refetch }: any = useUsersElastic(
    'authors',
    currentPageNumber,
    handleAnualFilter,
    // search,
  );

  useEffect(() => {
    if (currentPageNumber > 0 || handleAnualFilter.length > 0 || search !== '')
      setAllAuthors(users.users);
    else setAllAuthors([...authors, ...users.users]);
  }, [currentPageNumber, users.users, handleAnualFilter, search]);

  const date = new Date();

  const currentYear = date.getFullYear();

  useEffect(() => {
    if (users.length <= 0) {
      refetch();
    }
  }, [users]);

  useEffect(() => {
    setLoadingAnim(true);
    const searchTimeout = setTimeout(() => {
      setSearch(triggerSearch);
      setLoadingAnim(false);
    }, 800);
    return () => clearTimeout(searchTimeout);
  }, [triggerSearch]);
  console.log(users);

  const tableColumns = [
    {
      title: 'Autores',
      field: 'author',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '338px', color: '#A5ADBA' },
    },
    {
      title: 'Índice',
      filterPlaceholder: 'Filtrar',
      field: 'index',
      emptyValue: '-',
      cellStyle: { width: '100px' },
    },
    {
      title: `Artigos publicados em ${currentYear - 1}`,
      field: 'publishedArticlesYear',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { color: '#A5ADBA' },
    },
    {
      title: `Artigos publicados no quadriênio 2017 - 2020`,
      field: 'publishedArticlesQuadrennial',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { color: '#A5ADBA' },
    },
    {
      title: 'Status',
      field: 'status',
      filtering: false,
      emptyValue: '-',
      sorting: false,
      searchable: false,
    },
    {
      title: 'Ação',
      field: 'action',
      filtering: false,
      sorting: false,
      searchable: false,
    },
  ];

  const tableData = useMemo(() => {
    return allAuthors.map(author => {
      return {
        author: author?._source?.name || author.name,
        index: (
          <>
            {author?._source?.indexes?.['IC-A'] ? (
              <strong className="table-index">
                IC-A <span>{Number(author?._source?.indexes?.['IC-A'])}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
            {author?._source?.indexes?.['IC-H4-A'] ? (
              <strong className="table-index">
                IC-H4-A{' '}
                <span>{Number(author?._source?.indexes?.['IC-H4-A'])}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
            {author?._source?.indexes?.hAll ? (
              <strong className="table-index">
                Web-h4 <span>{Number(author?._source?.indexes?.hAll)}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
            {/* {author?._source?.indexes?.h5 && (
              <strong className="table-index">
                H5{' '}
                <span>
                  {Number(author?._source?.indexes?.h5)}
                </span>
                <br />
              </strong>
            )}
            {author?._source?.indexes?.i10All && (
              <strong className="table-index">
                i10All{' '}
                <span>
                  {Number(author?._source?.indexes?.i10All * 100).toFixed(2)}
                </span>
                <br />
              </strong>
            )}
            {author?._source?.indexes?.i105y && (
              <strong className="table-index">
                i10{' '}
                <span>
                  {Number(author?._source?.indexes?.i105y * 100).toFixed(2)}
                </span>
                <br />
              </strong>
            )} */}
          </>
        ),
        /* TODO: fazer o calculo com o filtro de ano */
        publishedArticlesYear: author?._source?.articlesRel?.filter(
          article => article.publicationYear === currentYear - 1,
        ).length,
        publishedArticlesQuadrennial: author?._source?.articlesRel?.filter(
          article =>
            article.publicationYear >= 2017 && article.publicationYear <= 2020,
        ).length,
        status:
          author?._source?.userId || author?.userId ? (
            <span className="table-green-tag">Verificado</span>
          ) : (
            <span className="table-green-outline-tag">Sem registro</span>
          ),
        action: (
          <Button
            link
            href={`/usuarios/autores/sobre/${
              author?._source?.uniqId || author?.id
            }`}
          >
            Ver mais
          </Button>
        ),
      };
    });
  }, [currentPageNumber, allAuthors]);

  return (
    // TODO: linkar o valor dos TotalCountCard
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <Section />
          <S.RowWrapper>
            <TotalCountCard
              title="Total de Autores na Plataforma"
              value={(authors.length + users.total).toLocaleString()}
            />
            <TotalCountCard
              title="Total de Autores Verificados"
              value={totalVerified.toLocaleString()}
            />
            <TotalCountCard
              title="Filtrar por ano"
              hasDateFilter
              handleDate={setHandleAnualFilter}
            />
            <TotalCountCard
              title="Filtrar por quadriênio"
              hasPeriodFilter
              handleDate={setHandleAnualFilter}
            />
          </S.RowWrapper>
          <DataTable
            tableTitle="Autores"
            columns={tableColumns}
            // onSearchChange={e => setTriggerSearch(e)}
            // options={{ search: true }}
            totalCount={authors.length + users.total}
            onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
            page={currentPageNumber}
            data={tableData}
            isLoading={isFetching || loadingAnim}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default Authors;
