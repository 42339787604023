import React, { useMemo, useState, useEffect } from 'react';
import Button from '../../../components/Button';
import DataTable from '../../../components/DataTable';
import Section from '../../../components/Section';
import TotalCountCard from '../../../components/TotalCountCard';
import { useUsers } from '../../../services/hooks/useUsers';
import { useUsersElastic } from '../../../services/hooks/useUsersElastic';
import * as S from './styles';

const Journals: React.FC = () => {
  const [allJounral, setAllJounral] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const { data: journals, isFetching } = useUsers('journal');
  const { data: journalsElastic } = useUsersElastic(
    'journals',
    currentPageNumber,
  );

  console.log(journalsElastic);

  const filterByVerification = journals?.filter(e => e?.isVerified === true);
  const filterByVerificationPending = journals?.filter(
    e => e?.verifications[0]?.status === 'waiting',
  );

  const filterBySubscriptions = journals?.filter(
    e => e?.subscriptions?.enabled === true,
  );

  useEffect(() => {
    if (currentPageNumber > 0) setAllJounral(journalsElastic.users);
    else setAllJounral([...journals, ...journalsElastic.users]);
  }, [currentPageNumber, journalsElastic.users]);

  const tableColumns = [
    {
      title: 'Revistas',
      field: 'journals',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { color: '#A5ADBA' },
    },
    {
      title: 'Índice',
      filterPlaceholder: 'Filtrar',
      field: 'index',
      emptyValue: '-',
    },
    {
      title: 'Artigos publicados no ano',
      field: 'publishedArticlesYear',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { color: '#A5ADBA' },
    },
    {
      title: 'Artigos publicados no quadriênio',
      field: 'publishedArticlesQuadrennial',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { color: '#A5ADBA' },
    },
    {
      title: 'Status',
      field: 'status',
      defaultSort: 'asc',
      emptyValue: '-',
    },
    {
      title: 'Ação',
      field: 'action',
      filtering: false,
      sorting: false,
      searchable: false,
    },
  ];

  const tableData = useMemo(() => {
    return allJounral.map(journal => {
      return {
        journals: journal?._source?.name || journal.name,
        accesses: journal.accesses,
        index: (
          <>
            {journal?._source?.indexes?.['IC-R'] ? (
              <strong className="table-index">
                IC-R <span>{Number(journal?._source?.indexes?.['IC-R'])}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
            {journal?._source?.indexes?.['IC-H4-R'] ? (
              <strong className="table-index">
                IC-H4-R{' '}
                <span>{Number(journal?._source?.indexes?.['IC-H4-R'])}</span>
                <br />
              </strong>
            ) : (
              ''
            )}
            {journal?._source?.indexes?.hAll && (
              <strong className="table-index">
                Web-h4 <span>{Number(journal?._source?.indexes?.hAll)}</span>
                <br />
              </strong>
            )}
            {journal?._source?.indexes?.sjr && (
              <strong className="table-index">
                SJR <span>{Number(journal?._source?.indexes?.sjr)}</span>
                <br />
              </strong>
            )}
            {/* {journal?._source?.indexes?.h5 && (
              <strong className="table-index">
                H5{' '}
                <span>
                  {Number(journal?._source?.indexes?.h5)}
                </span>
                <br />
              </strong>
            )} */}
            {/* {journal?._source?.indexes?.i10All && (
              <strong className="table-index">
                i10All{' '}
                <span>
                  {Number(journal?._source?.indexes?.i10All)}
                </span>
                <br />
              </strong>
            )} */}
            {/* {journal?._source?.indexes?.i105y && (
              <strong className="table-index">
                i10 <span>{Number(journal?._source?.indexes?.i105y)}</span>
                <br />
              </strong>
            )} */}
            {journal?._source?.stratum && (
              <strong className="table-index">
                Qualis <span>{journal?._source?.stratum}</span>
                <br />
              </strong>
            )}
          </>
        ),
        /* TODO: fazer o calculo com o filtro de ano */
        publishedArticlesYear: journal?.publishedArticles?.length,
        publishedArticlesQuadrennial: journal?.publishedArticles?.length,
        status: journal?.verifications?.length ? (
          journal?.verifications[0]?.status === 'approved' ? (
            <span className="table-green-tag">Verificado</span>
          ) : journal?.verifications[0]?.status === 'waiting' ? (
            <span className="table-red-tag">A verificar</span>
          ) : (
            <span className="table-green-outline-tag">Sem registro</span>
          )
        ) : (
          <span className="table-green-outline-tag">Sem registro</span>
        ),
        action: (
          <Button
            link
            href={`/usuarios/revista/sobre/${
              journal?.id || journal?._source?.id
            }`}
          >
            Ver mais
          </Button>
        ),
      };
    });
  }, [allJounral, currentPageNumber]);

  return (
    // TODO: linkar o valor dos TotalCountCard
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <Section />
          <S.RowWrapper>
            <TotalCountCard
              title="Total de Revistas na Plataforma"
              value={journals.length + journalsElastic.total}
            />
            <TotalCountCard
              title="Total de Revistas Assinantes"
              value={filterBySubscriptions.length}
            />
            <TotalCountCard
              title="Total de Revistas
              Verificados"
              value={filterByVerification.length}
              hasPending
              pendingValue={filterByVerificationPending.length}
            />
          </S.RowWrapper>
          <DataTable
            tableTitle="Revistas"
            columns={tableColumns}
            totalCount={journals.length + journalsElastic.total}
            onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
            page={currentPageNumber}
            data={tableData}
            isLoading={isFetching}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default Journals;
