import React, { useMemo, useState } from 'react';
import * as S from './styles';

export interface PersonalDataProps {
  title: string;
  data?: string;
  small?: boolean;
}

const PersonalData: React.FC<PersonalDataProps> = props => {
  return (
    <>
      <S.VeriFyedAuthorContainer {...props}>
        <h6 style={{ textTransform: 'capitalize' }}>{props.title}</h6>
        <div>
          <p>
            {props.data ? (
              props.data
            ) : (
              <p style={{ color: '#CAD0DA' }}>Dado não cadastrado</p>
            )}
          </p>
        </div>
      </S.VeriFyedAuthorContainer>
    </>
  );
};

export default PersonalData;
