import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Button from '../../../components/Button';
import ContentTag from '../../../components/ContentTag';
import Index from '../../../components/Index';
import TagWrapper from '../../../components/TagWrapper';
import { IArticle } from '../../../models/article';
import FetchingAnimation from '../../../components/FetchingAnimation';
import getClient from '../../../services/client/elasticsearch';
import * as S from './styles';

export interface ArticleDetailsProps extends IArticle {
  citationName?: string;
  citationNameSettings?: string;
}

const Articledetails: React.FC<ArticleDetailsProps> = props => {
  const [currentArticle, setCurrentArticle] = useState<any>([]);
  const { detalhe } = useParams<any>();

  const {
    data: articles,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    ['articles', detalhe],
    async () => {
      const response = await getClient().search({
        index: 'articles',
        size: 5,
        body: {
          query: {
            bool: {
              should: [
                {
                  match: {
                    uniqId: detalhe,
                  },
                },
                {
                  match: {
                    id: detalhe,
                  },
                },
                {
                  match: {
                    _id: detalhe,
                  },
                },
              ],
            },
          },
        },
      });
      const data = await response.hits.hits;
      return data;
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    if (!articles) return null;
    return setCurrentArticle(articles[0]?._source);
  }, [articles]);

  const postAuthors = useMemo(() => {
    if (currentArticle?.authorsRel?.length > 0) {
      return currentArticle.authorsRel;
    }
    return [];
  }, [articles, currentArticle]);

  const postKeyWords = useMemo(() => {
    if (currentArticle?.keywords?.length > 0) {
      return currentArticle?.keywords?.map(keyword => keyword);
    }
    return [];
  }, [articles, currentArticle]);

  const postReferences = useMemo(() => {
    if (currentArticle?.references?.length > 0) {
      return currentArticle?.references?.map(reference => reference);
    }
    return [];
  }, [articles, currentArticle]);

  const ICT = currentArticle?.indexes?.['IC-T'];
  console.log(currentArticle);

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <S.HeaderWrapper>
            <S.TitleWrapper>
              <S.TypeTitle>
                Texto {!isLoading && isFetching && <FetchingAnimation />}
              </S.TypeTitle>
              <h3>{currentArticle?.title}</h3>
            </S.TitleWrapper>

            <Index caciq indexName="Caciq-t" index={ICT} />
          </S.HeaderWrapper>

          <TagWrapper
            title="Autores do Texto"
            tags={postAuthors}
            total={postAuthors.length}
            href="/usuarios/autores/sobre"
          />

          <TagWrapper
            title="Palavras-chaves"
            tags={postKeyWords}
            total={postKeyWords.length}
          />
          <TagWrapper
            title="Textos Citados"
            tags={postReferences}
            total={postReferences.length}
            href="/texto/sobre"
          />

          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <S.DownloadContainer>
              <S.InfoContainer>
                <h4 className="info-titles">Site</h4>
                <S.InfoWrapper>
                  <p>{`https://dx.doi.org/${currentArticle?.DOI}` || ''}</p>
                </S.InfoWrapper>
              </S.InfoContainer>
              <S.InfoContainer>
                <h4 className="info-titles">Arquivo</h4>
                <S.InfoWrapper>
                  <p>
                    {currentArticle?.attached_file || 'Dado não cadastrado'}
                  </p>
                </S.InfoWrapper>
              </S.InfoContainer>
            </S.DownloadContainer>
            <S.ButtonWrapper>
              {currentArticle?.attached_file && (
                <Button
                  rightImage="download"
                  shadow
                  onClick={() => {
                    window.open(currentArticle?.attached_file);
                  }}
                >
                  Download
                </Button>
              )}
            </S.ButtonWrapper>
          </div>
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default Articledetails;
