import React, { ReactNode } from 'react';
import { ReactComponent as PositiveGrowthIcon } from '../../assets/positive-growth.svg';
import { ReactComponent as NegativeGrowthIcon } from '../../assets/negative-growth.svg';
import yearFilter from './yearFilter';

import AnonymousUserIcon from '../../assets/anonymous-user.svg';

import * as S from './styles';
import SelectInput from '../Select';

export interface TotalCountCardProps {
  title?: string;
  value?: any;
  hasDateFilter?: boolean;
  hasPeriodFilter?: boolean;
  hasPending?: boolean;
  pendingValue?: number;
  handleDate?: any;
}

const TotalCountCard: React.FC<TotalCountCardProps> = props => {
  const periodOptions = [
    {
      value: [],
      label: 'Todos',
    },
    {
      value: [2017, 2018, 2019, 2020],
      label: '2017 - 2020',
    },
  ];

  const yearSelector = yearFilter.map(year => {
    return {
      value: year === 'Todos' ? [] : [year],
      label: year.toString(),
    };
  });

  return (
    <>
      <S.Container {...props}>
        <S.DataWrapper>
          <div>
            <S.Title>{props.title}</S.Title>
            {props.hasPending && (
              <span className="pending">Pendentes: {props.pendingValue}</span>
            )}
            <div>
              {props.hasDateFilter && (
                <SelectInput
                  date
                  option={yearSelector}
                  customFunction={props.handleDate}
                />
              )}
              {props.hasPeriodFilter && (
                <SelectInput
                  date
                  option={periodOptions}
                  customFunction={props.handleDate}
                />
              )}
            </div>
          </div>
          <S.ValuesWrapper>
            <S.Value>{props.value}</S.Value>
          </S.ValuesWrapper>
        </S.DataWrapper>
      </S.Container>
    </>
  );
};

export default TotalCountCard;
