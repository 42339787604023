import React from 'react';
import EditButton from '../EditButton';
import * as S from './styles';

export interface SubscriptionsCardProps {
  direction?: 'row' | 'column';
  annualValue?: number;
  annualTitle?: string;
  monthlyValue?: number;
  hideMonth?: boolean;
  isIgnoringPayment?: boolean;
  title?: string;
  onClick?: () => void;
}

const SubscriptionsCard: React.FC<SubscriptionsCardProps> = props => {
  return (
    <>
      <S.Container {...props}>
        <S.DataWrapper>
          <S.Title>{props.title}</S.Title>
          <EditButton onClick={props.onClick} />
        </S.DataWrapper>

        <S.RowWrapper {...props}>
          <S.ValuesWrapper>
            <S.Subtitle>{props.annualTitle || 'Valor anual'}</S.Subtitle>
            {props.isIgnoringPayment ? (
              <h2 style={{ fontSize: '25px' }}>Ignorando pagamentos</h2>
            ) : (
              <h2>R$ {props.annualValue}</h2>
            )}
          </S.ValuesWrapper>
          {!props.hideMonth && (
            <S.ValuesWrapper>
              <S.Subtitle>Valor mensal</S.Subtitle>
              <h2 className="monthly-value">R$ {props.monthlyValue}</h2>
            </S.ValuesWrapper>
          )}
        </S.RowWrapper>
      </S.Container>
    </>
  );
};

export default SubscriptionsCard;
