import React from 'react';
import CsvDownload from 'react-json-to-csv';
import Button from '../../Button';
import { ReactComponent as Download } from '../../../assets/download.svg';
import * as S from '../styles';

export interface PaymentTableLineProps {
  paymentId?: string;
  paymentDate?: string;
  price?: number | string;
  downloadPDF?: any;
}

const PaymentTableTableLine: React.FC<PaymentTableLineProps> = props => {
  console.log(props.downloadPDF);
  return (
    <>
      <S.ContentWrapper>
        <S.LeftContent>
          <p className="data-text">{props.paymentDate}</p>
        </S.LeftContent>
        <S.RightContent>
          <p className="number-text">{props.price}</p>
        </S.RightContent>
        <S.RightContent style={{ width: '100%', textAlign: 'center' }}>
          <button type="button" title="download extrato">
            <CsvDownload filename="extrato.csv" data={[props.downloadPDF]}>
              <Download style={{ fill: '#6D216C' }} />
            </CsvDownload>
          </button>
        </S.RightContent>
      </S.ContentWrapper>
    </>
  );
};

export default PaymentTableTableLine;
