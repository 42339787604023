import styled, { css } from 'styled-components';
import { TotalCountCardProps } from '.';
import mq from '../../styles/mq';

export const Container = styled.div<TotalCountCardProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-grow: 1;
  height: 100px;
  padding: 20px;

  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 99;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .pending {
    font-size: 0.75rem;
    letter-spacing: 1px;
  }
`;

export const ValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
  text-align: start;
  max-width: 250px;
`;

export const Value = styled.h3`
  display: flex;
  font-size: 1.875rem;

  color: ${({ theme }) => theme.colors.blueMidtone};
`;
