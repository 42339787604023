/* eslint-disable no-alert */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { User } from '../models/user';
import api from '../services/api';

interface AuthState {
  token: string;
  user: User;
}

export interface AuthContextData {
  signed: boolean;
  loading: boolean;
  user: User | null;
  signIn(email: string, password: string, remember: boolean): Promise<void>;
  signOut(): void;
  updateProfile(user: User): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC = ({ children }: any) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('caciq/token');
    const user: User = JSON.parse(localStorage.getItem('caciq/user'));

    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, user };
    }

    return {} as AuthState;
  });
  const [loading, setLoading] = useState(false);

  const signIn = async (email: string, password: string, remember: boolean) => {
    try {
      setLoading(true);

      const response = await api.post('/sessions', { email, password });

      const { user, token } = response.data as unknown as {
        user: User;
        token: string;
      };

      if (user.plan.basicRole !== 'CaciqAdmin') {
        toast.info('Você não tem permissão para acessar esta página.');
        setLoading(false);
        return;
      }

      api.defaults.headers.Authorization = `Bearer ${token}`;
      if (remember) {
        localStorage.setItem('caciq/token', token);
        localStorage.setItem('caciq/user', JSON.stringify(user));
      } else {
        localStorage.setItem('caciq/token', token);
        localStorage.setItem('caciq/user', JSON.stringify(user));
        setTimeout(() => {
          localStorage.removeItem('caciq/token');
          localStorage.removeItem('caciq/user');
        }, 1000 * 120);
      }

      setData({ token, user });

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error('Erro ao efetuar login');
    }
  };

  const signOut = () => {
    localStorage.removeItem('caciq/token');
    localStorage.removeItem('caciq/user');
    delete api.defaults.headers.Authorization;
    setData({} as AuthState);
  };

  const updateProfile = (user: User) => {
    setData(oldData => ({ ...oldData, user: { ...oldData.user, ...user } }));
    localStorage.setItem(
      'caciq/user',
      JSON.stringify({
        ...data.user,
        ...user,
      }),
    );
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!data.user,
        loading,
        user: data.user,
        signIn,
        signOut,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}
