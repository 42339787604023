import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import TotalCountCard from '../../components/TotalCountCard';
import * as S from './styles';
import { IArticle } from '../../models/article';
import Button from '../../components/Button';
import DataTable from '../../components/DataTable';
import getClient from '../../services/client/elasticsearch';
import FetchingAnimation from '../../components/FetchingAnimation';
import { ReactComponent as PDFIcon } from '../../assets/pdf-btn.svg';

interface ArticlesProps extends IArticle {
  accesses?: number;
}

const Articles: React.FC<ArticlesProps> = props => {
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [handleAnualFilter, setHandleAnualFilter] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [triggerSearch, setTriggerSearch] = useState('');
  const [search, setSearch] = useState('');
  const [loadingAnim, setLoadingAnim] = useState(false);

  const defaultSearch = {
    index: 'articles',
    from: currentPageNumber * 5,
    size: 5,
    body: {
      sort: [{ 'indexes.IC-T': { order: 'desc' } }],
    },
  };

  const customSearch = {
    index: 'articles',
    from: currentPageNumber * 5,
    size: 5,
    body: {
      query: {
        bool: {
          should: [
            {
              match: {
                title: search,
              },
            },
            {
              match: {
                indexes: search,
              },
            },
            {
              match: {
                ppgs: search,
              },
            },
            {
              bool: {
                should: handleAnualFilter?.map(year => ({
                  match: { publicationYear: year },
                })),
              },
            },
          ],
        },
      },
    },
  };

  const {
    data: articles,
    isLoading,
    refetch,
  } = useQuery(
    ['articles', currentPageNumber, totalPages, handleAnualFilter, search],
    async () => {
      const response = await getClient().search(
        handleAnualFilter.length > 0 || search !== ''
          ? customSearch
          : defaultSearch,
      );
      const count = await getClient().count({ index: 'articles' });
      const data = await response.hits.hits;
      const totalData: any = await response.hits.total;
      console.log(data);

      if (handleAnualFilter.length > 0) {
        setTotalResults(totalData.value);
      } else {
        setTotalResults(count.count);
      }
      setTotalPages(totalData.value);

      const filteredArticles = data.map((article: any) => ({
        article: article?._source?.title,
        accesses: article?._source?.accesses,
        index: article?._source?.indexes,
        archive: article?._source?.attached_file,
        action: article?._source?.id,
      }));
      return filteredArticles;
    },
    {
      initialData: [],
      staleTime: 5000 * 60,
      refetchOnMount: 'always',
    },
  );

  useEffect(() => {
    if (articles.length <= 0) {
      refetch();
    }
  }, [articles]);

  const tableColumns = [
    {
      title: 'Texto',
      field: 'article',
      emptyValue: '-',
      filterPlaceholder: 'Filtrar',
      cellStyle: { width: '338px', color: '#A5ADBA' },
    },
    {
      title: 'Índice',
      filterPlaceholder: 'Filtrar',
      field: 'index',
      emptyValue: '-',
      render: article => (
        <strong
          style={{
            fontWeight: 900,
            fontSize: '14px',
            letterSpacing: '1px',
            color: '#49565A',
          }}
        >
          IC-T{' '}
          {article?.index?.['IC-T'] ? (
            <span style={{ color: '#1DCFCF' }}>
              {Number(article?.index?.['IC-T']).toFixed(2)}
            </span>
          ) : (
            <span style={{ color: '#1DCFCF' }}>-</span>
          )}
        </strong>
      ),
    },
    {
      title: 'Arquivo',
      field: 'archive',
      filtering: false,
      emptyValue: '-',
      sorting: false,
      searchable: false,
      render: article => (
        <a href={article.archive} target="_blank" rel="noreferrer">
          <PDFIcon />
        </a>
      ),
    },
    {
      title: 'Ação',
      field: 'action',
      filtering: false,
      sorting: false,
      searchable: false,
      render: article => (
        <Button link href={`/texto/sobre/${article?.action}`}>
          Ver mais
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setLoadingAnim(true);
    const searchTimeout = setTimeout(() => {
      setSearch(triggerSearch);
      setLoadingAnim(false);
    }, 800);
    return () => clearTimeout(searchTimeout);
  }, [triggerSearch]);

  return (
    // TODO: simular 'values' vindos do backend nos componentes TotalCountCard
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <S.RowWrapper>
            <TotalCountCard
              title="Total de Textos na Base"
              value={totalResults.toLocaleString()}
            />
            <TotalCountCard
              title="Filtrar por ano"
              hasDateFilter
              handleDate={setHandleAnualFilter}
            />
            <TotalCountCard
              title="Filtrar por quadriênio"
              hasPeriodFilter
              handleDate={setHandleAnualFilter}
            />
          </S.RowWrapper>

          <DataTable
            tableTitle="Textos"
            columns={tableColumns}
            onSearchChange={e => setTriggerSearch(e)}
            data={articles}
            options={{ search: true }}
            onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
            totalCount={totalPages}
            page={currentPageNumber}
            isLoading={loadingAnim || isLoading}
          />
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default Articles;
