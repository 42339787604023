import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const ContentWrapper = styled.div`
  padding: 23px 48px;
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 515px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  &::after {
    content: '';
    border-radius: 0px 10px 10px 0px;
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, 0));
    z-index: 0;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
  text-align: start;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

export const ValueWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: column;
  align-items: flex-start;

  &::-webkit-scrollbar {
    height: 2px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    transition: 0.3s;
  }
  &:hover&::-webkit-scrollbar-track {
    background: #ebebeb;
  }

  p {
    color: ${({ theme }) => theme.colors.grey4};
  }

  h3 {
    font-size: 1.875rem;
    padding-right: 20px;
    color: ${({ theme }) => theme.colors.blueMidtone};
  }
`;
