import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const Header = styled.header`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  height: 100px;
  padding: 0 48px;
`;

export const SearchButton = styled.button`
  border: none;
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0;
  cursor: pointer;
  outline: none;
  background: ${({ theme }) => theme.colors.blueDefault};
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.blueMidtone};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReturnButton = styled.button`
  border: none;
  padding: 12px 18px;
  margin: 0;
  margin-right: 20px;
  cursor: pointer;
  outline: none;
  background: transparent;
  transition: 0.2s;

  &:hover svg {
    fill: ${({ theme }) => theme.colors.blueMidtone};
  }
`;

export const LogoutButton = styled.button`
  border: none;
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0;
  cursor: pointer;
  outline: none;
  background: transparent;
  margin-left: 28px;

  font: ${({ theme }) => theme.fonts.workSans.p};
  color: ${({ theme }) => theme.colors.blueMidtone};
  font-size: 1rem;
  letter-spacing: 0.5px;
`;

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey2};

  p {
    margin-right: 71px;
  }

  span {
    ${({ theme }) => theme.fonts.workSans.p};
    color: ${({ theme }) => theme.colors.blueMidtone};
    font-weight: 700;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;
