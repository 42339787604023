import { useState } from 'react';
import { useQuery } from 'react-query';
import api from '../api';

// const [articleId, setArticleId] = useState<number>(0);

export function useUserDetail(id) {
  return useQuery(['users', id], () => getUser(id), {
    staleTime: 1000 * 60 * 60,
  });
}
async function getUser(id: number) {
  const { data }: any = await api.get('users');
  const filterSearch = data.filter(filter => filter.id === id);

  return filterSearch[0];
}
