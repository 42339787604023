import React from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Line from '../../assets/line-btn.png';
import { ButtonSection, Nav, ContainerRow } from './styles';

export interface DetailsSectionProps {
  paths?: any;
}

// TODO: condicionar href com o nome e role do usuário

const DetailsSection: React.FC<DetailsSectionProps> = props => {
  const history = useHistory();

  return (
    <>
      <Nav>
        <ul>
          {props.paths?.map(itens => (
            <li key={itens.id}>
              <ButtonSection disabledProp={itens.disabled}>
                {itens.goBack ? (
                  <button type="button" onClick={() => history.goBack()}>
                    <ContainerRow>
                      <span>{itens.title}</span>
                    </ContainerRow>
                    <img src={Line} alt="" className="divLine" />
                  </button>
                ) : (
                  <NavLink to={itens.href} activeClassName="active-link" exact>
                    <ContainerRow>
                      <span>{itens.title}</span>
                    </ContainerRow>
                    <img src={Line} alt="" className="divLine" />
                  </NavLink>
                )}
              </ButtonSection>
            </li>
          ))}
        </ul>
      </Nav>
    </>
  );
};

export default DetailsSection;
