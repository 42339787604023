import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 23px 48px;
  display: flex;
  flex-wrap: wrap;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
  margin-bottom: 40px;

  .analytics-btn {
    display: flex;
    width: 100%;
    background-color: #fff;
    height: 30px;
    justify-content: center;

    button {
      padding: 20px 60px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  ${mq.custom(
    css`
      flex-wrap: wrap;
      div {
        margin-top: 10px;
      }
    `,
    { minWidth: 0, maxWidth: 1180 },
  )}
`;

export const RequestedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  gap: 18px;
  margin-bottom: 40px;
`;
