import Modal from 'react-modal';
import { keyframes } from 'styled-components';

import React, { useState } from 'react';
import * as S from './styles';
import Button from '../Button';
import closeImg from '../../assets/close.svg';
import alertIcon from '../../assets/alert.svg';
import logoPopup from '../../assets/logo-popup.svg';

import articleIcon from '../../assets/article-icon.svg';
import ppgIcon from '../../assets/ppgIcon.svg';
import authorIcon from '../../assets/author-icon.svg';
import ContentTag from '../ContentTag';

export interface ModalContentProps {
  isOpen: boolean;
  title?: string;
  onRequestClose: () => void;
  modalType?: 'tag' | 'alert';
  type:
    | 'citedKeywords'
    | 'citedArticles'
    | 'verifiedArticles'
    | 'ppg'
    | 'citedAuthors';

  total?: number;
  tags: string[] | any;

  alertContent?: {
    icon: 'alert' | 'logo';
    title: string;
    text: string;
    buttonDefaultText: string;
    buttonSecondaryText: string;
    onClickDefault?: () => void;
    onClickGhost?: () => void;
  };
}

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;

const ModalContent: React.FC<ModalContentProps> = ({ ...props }) => {
  return (
    <>
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            top: '40px',
            height: 'fit-content',
            maxHeight: props.modalType === 'tag' ? '80%' : 'fit-content',
            margin: 'auto',
            width: 'fit-content',
            maxWidth: '50%',
            borderRadius: '20px',
            padding: props.modalType === 'tag' ? '40px' : '0px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <S.ModalContent>
          <button
            type="button"
            onClick={props.onRequestClose}
            style={{
              top: 20,
              right: 20,
              position: 'absolute',
              background: 'none',
              border: 'none',
              zIndex: 9999,
            }}
          >
            <img src={closeImg} alt="Fechar modal" />
          </button>
          <>
            {props.type && (
              <S.TitleContainer {...props}>
                <S.Title {...props}>
                  {props.type === 'citedKeywords' ? (
                    'Palavras-chave citadas'
                  ) : props.type === 'citedArticles' ? (
                    ' Artigos Citados nas referências'
                  ) : props.type === 'verifiedArticles' ? (
                    <>
                      <img src={articleIcon} alt="" /> Artigos Verificados
                    </>
                  ) : props.type === 'ppg' ? (
                    <>
                      <img src={ppgIcon} alt="" />
                      PPGs
                    </>
                  ) : props.type === 'citedAuthors' ? (
                    <>
                      <img src={authorIcon} alt="" />
                      Autores Citados
                    </>
                  ) : null}
                </S.Title>
              </S.TitleContainer>
            )}

            {props.title && (
              <S.TitleContainer {...props}>
                <S.Title {...props}>{props.title}</S.Title>
              </S.TitleContainer>
            )}

            <S.TagWrapper>
              {props?.tags?.map(tag => (
                <>
                  <S.ContainerTag>
                    <ContentTag large title={tag.name}>
                      {tag.name}
                    </ContentTag>
                  </S.ContainerTag>
                </>
              ))}
            </S.TagWrapper>
          </>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default ModalContent;
