import React from 'react';
import { ReactComponent as Edit } from '../../assets/edit-btn.svg';
import * as S from './styles';

interface EditButtonProps {
  onClick?: () => void;
}

const EditButton: React.FC<EditButtonProps> = props => {
  return (
    <>
      <S.StyledButton type="button">
        <Edit />
      </S.StyledButton>
    </>
  );
};

export default EditButton;
