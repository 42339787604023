import styled, { css } from 'styled-components';

import { NewSubscriptionModalProps } from '.';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
`;

export const TitleContainer = styled.div<NewSubscriptionModalProps>`
  display: flex;
  flex-direction: row;

  img {
    margin-right: 18px;
  }
`;

export const Title = styled.h5<NewSubscriptionModalProps>`
  display: flex;
  flex: 1;
  justify-content: center;

  color: ${({ theme }) => theme.colors.purpleDefault};
  font-weight: normal;
  font-size: 20px;
`;

export const Total = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3};
  span {
    font-weight: bold;
    font-size: 20px;

    letter-spacing: 0.5px;

    /* Caciq/ Blue/ Midtone */

    color: #17a1a1;
  }
`;

export const ButtonWrapper = styled.div<NewSubscriptionModalProps>`
  display: flex;
  gap: 18px;
  width: 100%;
  padding-top: 34px;

  button {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const StyledPopUp = styled.div`
  width: 100%;
  max-width: 636px;
  padding: 20px;
  border-radius: 10px;

  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;

  form {
    width: 100%;

    ul {
      width: 100%;

      li {
        label {
          display: block;
          margin-bottom: 4px;
        }
        & + li {
          margin-top: 12px;
        }
      }
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const TitleAlert = styled.h4`
  font-size: 1.56rem;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const InputWrapper = styled.div<NewSubscriptionModalProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    .css-1s2u09g-control {
      border-color: ${({ theme }) => theme.colors.blueMidtone};
      padding-left: 8px;
    }
  }

  .find-name {
    display: flex;
    gap: 20px;
    align-items: flex-end;

    svg {
      fill: white;
      margin: 0;
    }
  }

  .select-price-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .price-container {
      flex-grow: 1;

      input {
        width: 100%;
        height: 45px;
        margin-top: 12px;
      }
    }
  }

  .input-container {
    max-width: 374px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    padding-left: 10px;

    border: 1.5px solid ${({ theme }) => theme.colors.blueMidtone};

    ul {
      width: 100%;
      display: flex;
      max-height: 140px;
      overflow-y: auto;
      flex-direction: column;

      li {
        display: flex;

        span {
          color: ${({ theme }) => theme.colors.grey2};

          & + span {
            margin-left: 30px;
          }
        }
        & + li {
          margin-top: 20px;
        }
      }
    }
  }

  .ignore-payment-container {
    display: flex;
    align-items: center;
    margin: 10px 0;

    > input {
      flex: 0;
    }

    > label {
      margin: 0 0 0 10px;
      > span {
        color: #999;
        font-size: 11px;
      }
    }
  }

  label {
    margin: 16px 0;
    font: ${({ theme }) => theme.fonts.mulish.h6};
    color: ${({ theme }) => theme.colors.blueMidtone};
    font-size: 0.75rem;
  }

  input {
    flex-grow: 1;
    padding: 10px;
    border: 1.5px solid ${({ theme }) => theme.colors.blueMidtone};
    border-radius: 10px;

    &:disabled {
      border: 1.5px solid #dadee5;
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.grey5};
    }
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 18px;
`;
