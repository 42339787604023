import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import api from '../../services/api';
import * as S from './styles';

export interface SwitchProps {
  checked?: boolean;
  id?: string;
  type?: string;
  refetch?: () => void;
  permissions?: string[];
}

const SwitchComponent: React.FC<SwitchProps> = ({
  checked = false,
  id,
  type,
  refetch,
  permissions,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = async () => {
    try {
      if (!isChecked) {
        const response = await api.put(`/plans/${id}`, {
          permissions: [...permissions, type],
        });
        refetch();
        toast.success('Permissão alterada');
        setIsChecked(true);
      } else {
        const itemIndex = permissions.indexOf(type);
        if (itemIndex > -1) {
          permissions.splice(itemIndex, 1);
          const response = await api.put(`/plans/${id}`, {
            permissions,
          });
          refetch();
          setIsChecked(false);
          toast.success('Permissão alterada');
        }
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <S.SwitchContainer>
        <Switch
          onChange={() => handleChange()}
          checked={isChecked}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
          handleDiameter={14}
          onColor="#1AA"
          onHandleColor="#9FF;"
          offColor="#ABC"
          offHandleColor="#DDE"
          activeBoxShadow="0 0 1px 1px #1AA"
        />
      </S.SwitchContainer>
    </>
  );
};

export default SwitchComponent;
