import MaterialTable, { MaterialTableProps } from 'material-table';
import React from 'react';

import * as S from './styles';

interface DataTableProps extends Partial<MaterialTableProps<any>> {
  tableTitle?: string;
  data?: any;
  columns?: any;
  editable?: any;
  options?: any;
  isLoading?: boolean;
  style?: any;
  results?: number;
}

const DataTable: React.FC<DataTableProps> = props => {
  const tableStyle = {
    width: '100%',
    padding: '23px 36px',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    color: 'black',
    border: '1.5px solid #DADEE5',
    textAlign: 'center',
    ...props.style,
  };

  const tableOptions = {
    pageSize: 5,
    emptyRowsWhenPaging: false,
    search: false,
    pageSizeOptions: 0,
    actionsColumnIndex: -1,
    addRowPosition: 'first',
    actionsCellStyle: {
      textAlign: 'end',
    },
    ...props.options,
  };

  const tableLocalization = {
    toolbar: { searchPlaceholder: 'Buscar' },
    body: {
      addTooltip: 'Adicionar novo',
      editRow: {
        deleteText: 'Você tem certeza que quer excluir o usuário?',
        cancelTooltip: 'Cancelar',
        saveTooltip: 'Sim, excluir',
      },
      emptyDataSourceMessage: 'Nenhum dado para exibir',
    },
    header: { actions: 'Ações' },
    pagination: {
      labelDisplayedRows: `{from} de {count}`,
      labelRowsSelect: 'linhas',
    },
  };

  return (
    <S.RowWrapper>
      <div style={{ width: '100%' }}>
        <MaterialTable
          data={props.data}
          columns={props.columns}
          title={props.tableTitle}
          options={tableOptions}
          style={tableStyle}
          localization={tableLocalization}
          editable={props.editable}
          isLoading={props.isLoading}
          {...props}
        />
      </div>
    </S.RowWrapper>
  );
};

export default DataTable;
