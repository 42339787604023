import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;

  width: 181px;
  height: 100vh;

  margin: 0px;
  padding: 0px;

  background: ${({ theme }) => theme.colors.purpleMidtone};

  position: sticky;
  box-sizing: border-box;
  top: 0;
  left: 0;
`;

export const LogoWrapper = styled.div`
  height: 276px;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding-top: 57px;
`;

export const NavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .is-active {
    background: ${({ theme }) => theme.colors.purpleDark};
    border-left: 4px solid ${({ theme }) => theme.colors.white};
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 14px;

    a {
      display: flex;
      align-items: center;
      padding-left: 48px;
      height: 44px;
      width: 181px;
      font: ${({ theme }) => theme.fonts.mulish.button};
      color: ${({ theme }) => theme.colors.white};
      letter-spacing: 0.3px;
      transition: 0.2s;

      &:hover,
      &:active {
        background: ${({ theme }) => theme.colors.purpleDark};
        border-left: 4px solid ${({ theme }) => theme.colors.white};
      }

      &:active {
        font-weight: bold;
      }
    }
  }
`;
export const BottomWrapper = styled.div`
  height: 219px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-bottom: 36px;
  padding: 0 20px;

  text-align: center;
  font-size: 0.625rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`;
