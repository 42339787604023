import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';

// TODO: FUNCIONALIDADE: ao clicar numa tag, ela deverá fazer uma busca com filtro do valor do seu conteúdo
export interface ContentTagProps {
  large?: boolean;
  title?: string;
  href?: string;
}

const ContentTag: React.FC<ContentTagProps> = ({
  children,
  large,
  title,
  href,
}) => {
  return (
    <>
      <S.StyledtTag large={large} title={title}>
        <Link to={href || '#'}>{children}</Link>
      </S.StyledtTag>
    </>
  );
};

export default ContentTag;
