import React, { useState, useEffect, useRef } from 'react';
import { Button } from './styles';

interface SelectorProp {
  items?: string[] | any;
  setNewValue: any;
  formName: string;
}

const Selector: React.FC<SelectorProp> = ({
  items = [],
  setNewValue,
  formName,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const buttonRef = useRef(null);

  const handleNewValue = () => {
    setIsSelected(!isSelected);
    if (!isSelected) {
      setNewValue({
        label: items?.name,
        value: items?.id,
      });
    } else setNewValue('');
  };

  // useEffect(() => {
  //   if (buttonRef?.current?.onclick !== undefined) {
  //     setIsSelected(true);
  //   } else setIsSelected(false);
  // }, [formName]);

  return (
    <Button
      selectProps={isSelected}
      disabledProp={!!items?.subscriptions || !items.isEmailVerified}
      onClick={handleNewValue}
      ref={buttonRef}
    >
      <span />{' '}
      <p>
        {items.title ||
          `${items?.name} - ${items?.email}` ||
          items?._source?.name}
      </p>
    </Button>
  );
};

export default Selector;
