import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const ContentWrapper = styled.div`
  padding: 23px 48px;
  padding-right: 155px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  margin-bottom: 40px;

  h4 {
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 52px;
  }

  .info-titles {
    align-self: flex-end;
    height: 100%;
  }

  .date-selector {
    display: flex;
    align-items: center;
    gap: 32px;

    ul {
      flex: 1;
      display: flex;
      gap: 22px;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .select-container {
      max-width: 92px;

      .css-1s2u09g-control {
        margin-top: 0 !important;
      }
    }

    label {
      ${({ theme }) => theme.fonts.mulish.h6}
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  padding: 33px 0;

  border-top: 1px solid ${({ theme }) => theme.colors.grey5};

  h4 {
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
`;

export const TagWrapper = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  gap: 18px;
`;
