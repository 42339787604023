import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertModal from '../../../components/AlertModal';
import PaymentHistoryCard from '../../../components/PaymentHistoryCard';
import { PaymentData } from '../../../components/PaymentHistoryCard/fakeData';
import PaymentMethodCard from '../../../components/PaymentMethodCard';
import PersonalInfoCard from '../../../components/PersonalInfoCard';
import PlanCard from '../../../components/PlanCard';
import DetailsSection from '../../../components/DetailsSection';
import { useUserDetail } from '../../../services/hooks/useUserDetail';
import { UserDetailsProps } from '../Researchers/ResearcherDetails';
import { useSubscriptionDetails } from '../../../services/hooks/useSubscriptionDetails';
import api from '../../../services/api';
import * as S from './styles';

const Billing: React.FC<UserDetailsProps> = props => {
  const { id } = useParams<any>();

  const { data: subscription } = useSubscriptionDetails(id) as any;

  console.log(subscription);

  const accessType = subscription?.accessType;

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isCanceledAccountModalOpen, setIsCanceledAccountModalOpen] =
    useState(false);

  function handleOpenModal() {
    setIsVerificationModalOpen(true);
  }

  function handleCloseModal() {
    setIsVerificationModalOpen(false);
  }

  async function handleOpenCanceledModal() {
    try {
      const response = await api.delete(
        `/dashboard/subscriptions/${subscription?.id}`,
      );
      toast.success('Plano deletado');
      setIsVerificationModalOpen(false);
      setIsCanceledAccountModalOpen(true);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  function handleCloseCanceledModal() {
    setIsCanceledAccountModalOpen(false);

    const path = '/usuarios/pesquisadores';
    history.push(path);
  }

  const history = useHistory();

  const rolePluralTranslation = {
    researcher: 'pesquisadores',
    author: 'autores',
    ppg: 'ppgs',
    journal: 'revistas',
  };

  const roleTranslation = {
    researcher: ' Pesquisador ',
    author: ' Autor',
    ppg: ' PPG',
    journal: 'Revista',
  };

  const linkItems = [
    {
      id: 0,
      title: 'Sobre',
      href: `/usuarios/${rolePluralTranslation[accessType] || ''}/sobre/${
        subscription?.payerId
      }`,
      disabled: false,
      goBack: true,
    },
    {
      id: 1,
      title: 'Cobrança',
      href: `/cobranca/${id}`,
    },
  ];

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <DetailsSection paths={linkItems} />
          <S.HeaderWrapper>
            <S.TitleWrapper>
              <S.TypeTitle>{subscription?.payer?.name}</S.TypeTitle>
            </S.TitleWrapper>
          </S.HeaderWrapper>

          <S.ColumnWrapper>
            <S.PaymentContainer>
              <S.PaymentWrapper>
                <PlanCard
                  basicRole={roleTranslation[accessType] || ' Sem Plano'}
                  buttonText="Cancelar plano"
                  buttonClick={handleOpenModal}
                />
                <PaymentMethodCard
                  creditCardNumber={subscription?.card?.last4}
                  cardName={subscription?.card?.brand}
                />
              </S.PaymentWrapper>
              <PaymentHistoryCard
                paymentHistory={subscription?.invoices?.data}
              />
            </S.PaymentContainer>
            <PersonalInfoCard
              name={subscription?.payer?.name || 'Dado não cadastrado'}
              email={subscription?.payer?.email || 'Dado não cadastrado'}
              link={`/usuarios/${
                rolePluralTranslation[accessType] || ''
              }/sobre/${subscription?.payer?.id}`}
            />
          </S.ColumnWrapper>
        </S.ContentWrapper>
      </S.MainContainer>
      <AlertModal
        isOpen={isVerificationModalOpen}
        onRequestClose={handleCloseModal}
        alertContent={{
          title: 'Você tem certezaque quer cancelar o plano',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue risus sagittis neque condimentum bibendum viverra. Tellus quisque sed enim faucibus amet, eu, sed vulputate. Leo tellus enim mi.',
          defaultButtonText: 'Voltar',
          dangerButtonText: 'Sim, cancelar',
          onClickDanger: handleOpenCanceledModal,
          onClickDefault: handleCloseModal,
        }}
      />
      <AlertModal
        isOpen={isCanceledAccountModalOpen}
        onRequestClose={handleCloseCanceledModal}
        alertContent={{
          title: 'Plano Cancelado',
          defaultButtonText: 'Voltar',
          onClickDefault: handleCloseCanceledModal,
        }}
      />
    </>
  );
};

export default Billing;
