import React, { useState, useEffect } from 'react';
import ContentTag from '../ContentTag';
import yearFilter from '../TotalCountCard/yearFilter';
import SelectInput from '../Select';
import * as S from './styles';

export interface TagWrapperProps {
  title?: string;
  tags?: any;
  radio?: {
    options: any;
  };
  muttable?: boolean;
  type?: string;
  href?: string;
  total?: number;
}

const TagWrapper: React.FC<TagWrapperProps> = props => {
  const [dateFilter, setDateFilter] = useState(false);
  const [filter, setFilter] = useState([]);

  const periodOptions = [
    {
      value: [],
      label: 'Todos',
    },
    {
      value: [2017, 2018, 2019, 2020],
      label: '2017 - 2020',
    },
  ];

  const yearSelector = yearFilter.map(year => {
    return {
      value: year === 'Todos' ? [] : [year],
      label: year.toString(),
    };
  });

  return (
    <>
      <S.ColumnWrapper>
        <S.RowWrapper
          style={{ marginBottom: '0px', justifyContent: 'space-between' }}
        >
          <h4 style={{ marginBottom: '30px' }}>
            {props.title}

            {props.muttable && (
              <div className="date-selector">
                <ul className="radio-container">
                  <li>
                    <label htmlFor="year">Ano</label>
                    <input
                      type="radio"
                      id="year"
                      name="date"
                      value="year"
                      onChange={() => setDateFilter(false)}
                    />
                  </li>
                  <label htmlFor="4year">Quadrienio</label>
                  <input
                    type="radio"
                    id="4year"
                    name="date"
                    value="4year"
                    onChange={() => setDateFilter(true)}
                  />
                </ul>
                <div className="select-container">
                  <SelectInput
                    customFunction={setFilter}
                    option={!dateFilter ? yearSelector : periodOptions}
                  />
                </div>
              </div>
            )}
          </h4>
          <p>Total: {props.total || 0}</p>
        </S.RowWrapper>

        {props.muttable ? (
          props.tags && filter.length <= 0 ? (
            <>
              <S.TagWrapper>
                {props.tags?.map(tag => (
                  <ContentTag href={`/texto/sobre/${tag?.uniqId || tag}`}>
                    {tag?.title || tag}
                  </ContentTag>
                ))}
              </S.TagWrapper>
            </>
          ) : props.tags && filter.length > 0 ? (
            <S.TagWrapper>
              {props.tags
                ?.filter(e =>
                  filter.length > 1
                    ? e.publicationYear > filter[0] &&
                      e.publicationYear < filter[3]
                    : e.publicationYear === filter[0],
                )
                ?.map(tag => (
                  <ContentTag href={`/texto/sobre/${tag?.uniqId || tag}`}>
                    {tag?.title || tag}
                  </ContentTag>
                ))}
            </S.TagWrapper>
          ) : (
            <p style={{ color: '#CAD0DA' }}>Sem dados para exibir</p>
          )
        ) : props.tags?.length > 0 ? (
          <S.TagWrapper>
            {props.tags.map(tag => (
              <ContentTag
                href={
                  props.href
                    ? `${props.href}/${tag?.uniqId || tag?._id || tag}`
                    : null
                }
              >
                {tag?.title || tag?.name || tag}
              </ContentTag>
            ))}
          </S.TagWrapper>
        ) : (
          <p style={{ color: '#CAD0DA' }}>Sem dados para exibir</p>
        )}
      </S.ColumnWrapper>
    </>
  );
};

export default TagWrapper;
