import styled, { css } from 'styled-components';
import mq from '../../../styles/mq';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const ContentWrapper = styled.div`
  padding: 23px 48px;
`;

export const TitleWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
`;

export const TypeTitle = styled.p`
  margin-bottom: 0px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  margin-bottom: 40px;

  h3 {
    letter-spacing: 1px;
  }

  .info-titles {
    align-self: flex-end;
    height: 100%;
  }
`;

export const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 114px;
  width: 100%;

  h2 {
    color: ${({ theme }) => theme.colors.blueDefault};
  }

  h6 {
    font-weight: 900;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  padding: 25px 0;

  h4 {
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
`;
export const RequestedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  gap: 18px;
  margin-bottom: 40px;
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;

  gap: 18px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 272px;
  width: 100%;
  margin-bottom: 30px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};

  p {
    padding: 13px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  height: fit-content;
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  margin-left: 45px;
  margin-bottom: 25px;
`;
