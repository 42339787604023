import { useQuery } from 'react-query';
import { SubscriptionsDetailsProps } from '../../screens/Subscriptions/SubscriptionsDetails';
import api from '../api';

async function getSubscriptions(
  id: number,
): Promise<SubscriptionsDetailsProps> {
  const { data } = await api.get(`/dashboard/subscriptions/detail/${id}`);

  return data;
}

export function useSubscriptionDetails(id) {
  return useQuery(['subscriptions-details', id], () => getSubscriptions(id), {
    staleTime: 1000 * 60 * 60,
  });
}
