/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AuthContext } from '../../hooks/auth';
import LoadingScreen from '../../components/LoadingScreen';
import Logo from '../../assets/logo-caciq.svg';
import * as S from './styles';

interface FormInputs {
  email: string;
  password: string;
  remember: boolean;
}

const schema = Yup.object().shape({
  email: Yup.string().required('O E-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

const LoginPage = () => {
  const { signIn, loading } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FormInputs> = data => {
    try {
      signIn(data.email, data.password, data.remember);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <S.MainContainer>
      {loading ? <LoadingScreen /> : null}
      <S.Container>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
          }}
        >
          <img src={Logo} width="180px" alt="" />
        </div>
        <h2>
          Login<span> Dashboard</span>
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="email">E-mail</label>
            <input name="email" id="name" type="email" {...register('email')} />
            <p> {errors.email?.message}</p>
          </div>
          <div>
            <label htmlFor="password">Senha</label>
            <input
              name="password"
              id="password"
              type="password"
              {...register('password')}
            />
            <p> {errors.password?.message}</p>
          </div>

          <div className="forgot-password-section">
            <div>
              <input
                type="checkbox"
                id="remember"
                name="remember"
                {...register('remember')}
              />
              <label htmlFor="remember">Lembrar de mim</label>
            </div>

            <a href="https://google.com"> Esqueci a senha </a>
          </div>

          <div className="buttons-section">
            <button type="submit">Login</button>
            {/* <button type="button" className="orcid-login">
              Login com ORCID
            </button> */}
          </div>
        </form>
      </S.Container>
    </S.MainContainer>
  );
};

export default LoginPage;
