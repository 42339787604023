import styled, { css } from 'styled-components';
import { SubscriptionsCardProps } from '.';
import mq from '../../styles/mq';

export const Container = styled.div<SubscriptionsCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-width: ${props => (props.direction === 'column' ? '330px' : '340px')};
  max-width: ${props => (props.direction === 'column' ? '330px' : '100%')};

  padding: 20px;

  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid #dadee5;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .pending {
    font-size: 0.75rem;
    letter-spacing: 1px;
  }
`;

export const RowWrapper = styled.div<SubscriptionsCardProps>`
  display: flex;
  flex-direction: ${props => (props.direction === 'column' ? 'column' : 'row')};
  justify-content: space-between;
  width: 100%;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.blueMidtone};
  }

  .monthly-value {
    color: ${({ theme }) => theme.colors.purpleDefault};
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
  text-align: start;
  width: 200px;
`;

export const Value = styled.h3`
  display: flex;
  font-size: 1.875rem;

  color: ${({ theme }) => theme.colors.blueMidtone};
`;
