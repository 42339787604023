/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertModal from '../../../../components/AlertModal';
import Button from '../../../../components/Button';
import DetailsSection from '../../../../components/DetailsSection';
import Index from '../../../../components/Index';
import PersonalData from '../../../../components/PersonalData';
import TagWrapper from '../../../../components/TagWrapper';
import { IJournal } from '../../../../models/journal';
import { ISocials } from '../../../../models/user';
import api from '../../../../services/api';
import { useUsersElasticDetails } from '../../../../services/hooks/useUsersElasticDeatil';
import { useUserDetail } from '../../../../services/hooks/useUserDetail';

import * as S from './styles';

export interface JournalDetailsProps extends IJournal {
  email?: string;
  responsibleEditor?: string;
  socials?: ISocials[];
  publishedArticles?: string[];
  mostCitedArticles: string[];
  mostCitedKeyWords: string[];
}

const JournalDetails: React.FC<JournalDetailsProps> = props => {
  const { id } = useParams<any>();

  const { data: journal } = useUserDetail(id);
  const { data: journalElastic }: any = useUsersElasticDetails('journals', id);

  const journalItems = [
    {
      id: 0,
      title: 'Sobre',
      disabled: false,

      href: `/usuarios/revista/sobre/${id}`,
    },
    {
      id: 1,
      title: 'Cobrança',
      disabled: !journal?.subscriptions || false,

      href: `/cobranca/${journal?.subscriptions?.id}`,
    },
  ];

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isVerifyedAccountModalOpen, setIsVerifyedAccountModalOpen] =
    useState(false);

  function handleOpenModal() {
    setIsVerificationModalOpen(true);
  }

  function handleCloseModal() {
    setIsVerificationModalOpen(false);
  }

  async function handleOpenVerifyedModal() {
    try {
      const approve = {
        verificationId: journal?.verifications[0]?.id,
        approve: true,
      };

      const reponse = await api.post(
        '/dashboard/verification/approve/',
        approve,
      );
      toast.success('Periódico Aprovado');
      setIsVerificationModalOpen(false);
      setIsVerifyedAccountModalOpen(true);
      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  function handleCloseVerifyedModal() {
    setIsVerifyedAccountModalOpen(false);
  }

  const openVerificationFile = () => {
    window.open(journal?.verifications[0]?.url);
  };

  const editorResponsible = journal?.socials?.filter(
    e => e.name === 'editorResponsible',
  );
  const issn = journal?.socials?.filter(e => e.name === 'issn');

  console.log(journal);

  const ICR = journalElastic[0]?._source?.indexes?.['IC-R'];

  const ICH4 = journalElastic[0]?._source?.indexes?.['IC-H4-R'];

  const HALL = journalElastic[0]?._source?.indexes?.hAll;

  const H5 = journalElastic[0]?._source?.indexes?.h5;

  const I10All = journalElastic[0]?._source?.indexes?.i10All;

  const I10 = journalElastic[0]?._source?.indexes?.i105y;

  const SJR = journalElastic[0]?._source?.indexes?.sjr;

  const qualis = journalElastic[0]?._source?.stratum;

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <DetailsSection paths={journalItems} />
          <S.HeaderWrapper>
            <S.TitleWrapper>
              <S.TypeTitle>Revista</S.TypeTitle>
              <h3>{journal?.name || journalElastic[0]?._source?.name}</h3>
            </S.TitleWrapper>
            {ICR ? <Index index={ICR} caciq indexName="IC-R" /> : null}
          </S.HeaderWrapper>

          <S.OtherIndexesWrapper>
            <h4
              style={{
                fontSize: '20px',
                fontWeight: 700,
                letterSpacing: '1px',
              }}
            >
              Outros índices
            </h4>
            {ICH4 ? <Index index={ICH4} indexName="IC-H4-A" /> : ''}
            {HALL && <Index index={HALL} indexName="Web-h4" />}
            {/* {H5 && <Index index={H5} indexName="H5" />}
            {I10All && <Index index={I10All} indexName="i10All" />}
            {I10 && <Index index={I10} indexName="i10" />} */}
            {SJR && <Index index={SJR} indexName="SJR" />}
            {qualis && <Index index={qualis} qualis indexName="Qualis" />}
          </S.OtherIndexesWrapper>

          {journal?.verifications[0]?.url &&
            journal?.verifications[0]?.status !== 'approved' && (
              <S.VerifyContainer>
                <S.InfoContainer>
                  <h4 className="info-titles">
                    Arquivo de <br /> Verificação
                  </h4>
                  <S.InfoWrapper>
                    <p>{journal?.verifications[0]?.url} </p>
                  </S.InfoWrapper>
                </S.InfoContainer>
                <S.ButtonWrapper>
                  <Button
                    rightImage="download"
                    ghost
                    size="small"
                    onClick={openVerificationFile}
                  >
                    Download
                  </Button>

                  <Button color="blueMidtone" shadow onClick={handleOpenModal}>
                    Verificar
                  </Button>
                </S.ButtonWrapper>
              </S.VerifyContainer>
            )}

          <S.ColumnWrapper>
            <h4>Dados</h4>
            <S.PersonalDataRow>
              <PersonalData title="E-mail" data={journal?.email} small />
              <PersonalData
                title="Editor responsável"
                data={editorResponsible?.map(e => e.url)}
                small
              />
              <PersonalData
                title="ISSN"
                data={journalElastic[0]?._source?.issn || issn?.map(e => e.url)}
                small
              />
            </S.PersonalDataRow>

            <S.PersonalDataRow>
              {journal?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('site') && (
                    <PersonalData title="Site Oficial" data={item.url} small />
                  ),
              )}
              {journal?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('linkedin') && (
                    <PersonalData title="Linkedin" data={item.url} small />
                  ),
              )}
              {journal?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('facebook') && (
                    <PersonalData title="Facebook" data={item.url} small />
                  ),
              )}
            </S.PersonalDataRow>

            <S.PersonalDataRow style={{ marginBottom: '40px' }}>
              {journal?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('twitter') && (
                    <PersonalData title="Twitter" data={item.url} small />
                  ),
              )}
              {journal?.socials?.map(
                item =>
                  item.name.toLowerCase().includes('instagarm') && (
                    <PersonalData title="Instagram" data={item.url} small />
                  ),
              )}
            </S.PersonalDataRow>

            <TagWrapper
              title="Textos Publicados"
              tags={journalElastic[0]?._source?.articles}
              total={journalElastic[0]?._source?.articles?.length.toLocaleString()}
            />

            <TagWrapper
              title="Citações mais usadas"
              tags={journal?.mostCitedArticles}
              total={journalElastic[0]?._source?.mostCitedArticles?.length}
            />

            <TagWrapper
              title="Palavras-chave mais usadas"
              tags={journal?.mostCitedKeyWords}
              total={journalElastic[0]?._source?.mostCitedKeyWords?.length}
            />
          </S.ColumnWrapper>
        </S.ContentWrapper>
      </S.MainContainer>
      <AlertModal
        isOpen={isVerificationModalOpen}
        onRequestClose={handleCloseModal}
        alertContent={{
          title: 'Confirmar Verificação',
          document: 'www',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue risus sagittis neque condimentum bibendum viverra. Tellus quisque sed enim faucibus amet, eu, sed vulputate. Leo tellus enim mi.',
          defaultButtonText: 'Verificar',
          dangerButtonText: 'Cancelar',
          onClickDanger: handleCloseModal,
          onClickDoc: openVerificationFile,
          onClickDefault: handleOpenVerifyedModal,
        }}
      />
      <AlertModal
        isOpen={isVerifyedAccountModalOpen}
        onRequestClose={handleCloseVerifyedModal}
        alertContent={{
          title: 'Conta Verificada',
          defaultButtonText: 'Voltar',
          onClickDefault: handleCloseVerifyedModal,
        }}
      />
    </>
  );
};

export default JournalDetails;
