/* eslint-disable consistent-return */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { ReactComponent as Search } from '../../assets/search-right.svg';
import { ReactComponent as Download } from '../../assets/download.svg';

export interface ButtonProps {
  ghost?: boolean;
  size?: 'small' | 'big';
  shadow?: boolean;
  link?: boolean;
  href?: string;
  rightImage?: 'arrow' | 'search' | 'download';
  typeOf?: string;
  search?: boolean;
  arrow?: boolean;

  /* the color will be 'purple' if color isn't set */
  color?: 'blueMidtone' | 'blueDefault' | 'redMidtone';
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  rightImage,
  href,
  onClick,
  ...props
}) => {
  const history = useHistory();
  const renderImage = useCallback(() => {
    const color = props.ghost ? '#932D91' : 'white';

    if (!rightImage) {
      return null;
    }

    if (rightImage === 'arrow') {
      return <ArrowRight style={{ marginLeft: 10 }} fill={color} />;
    }
    if (rightImage === 'search') {
      return <Search style={{ marginLeft: 10 }} fill={color} />;
    }
    if (rightImage === 'download') {
      return <Download style={{ marginLeft: 10 }} fill={color} />;
    }

    return null;
  }, [rightImage, props.ghost]);

  const handleButton = () => {
    if (!href && onClick) {
      onClick();
    } else if (href) {
      history.push(href);
    } else return null;
  };

  return (
    <S.StyledButton {...props} onClick={() => handleButton()}>
      {children}
      {renderImage()}
    </S.StyledButton>
  );
};

export default Button;
