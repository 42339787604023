import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const ContentWrapper = styled.div`
  padding: 23px 48px;
  display: flex;
  flex-direction: column;
  gap: 34px;
`;
