import styled, { css } from 'styled-components';
import mq from '../../styles/mq';

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  gap: 18px;
  margin-bottom: 40px;

  .MuiTableCell-footer.MuiTableCell-footer {
    border: 0 !important;
  }
`;
