import React, { useState, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import SelectInput from '../../../../components/Select';
import * as S from './styles';
import api from '../../../../services/api';
import Button from '../../../../components/Button';
import closeImg from '../../../../assets/close.svg';

export interface NewUsersModalProps {
  isOpen: boolean;
  formResponse?: any;
  formRequest?: any;
  onRequestClose: () => void;
  subscriptions?: string[] | any;
  subscriptionId?: string;
  alertContent?: {
    title?: string;
    attachment?: boolean;
    text?: string;
    defaultButtonText?: string;
    ghostButtonText?: string;
    dangerButtonText?: string;
    namesFound?: number;
    onClickDefault?: () => void;
    onClickGhost?: () => void;
    onClickDanger?: () => void;
  };
  inputs?: {
    type?: 'input' | 'select' | 'upload' | 'preview';
    id?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    defaultValue?: string;
    isEditable?: boolean;
  }[];
}

const NewUsersModal: React.FC<NewUsersModalProps> = ({ ...props }) => {
  const [userList, setUserList] = useState<any>([]);

  const inputRef = useRef(null);

  const selectOptions = [
    {
      value: 'pesquisador',
      label: 'Pesquisador',
    },
    {
      value: 'autor',
      label: 'Autor',
    },
    {
      value: 'periodico',
      label: 'Revistas',
    },
    {
      value: 'ppg',
      label: 'PPG',
    },
  ];

  async function handleSubmit() {
    try {
      const newList = props.subscriptions.map(e => ({
        email: e.email,
        name: e.name,
      }));

      const response = await api.put(
        `/dashboard/subscriptions/${props.subscriptionId}`,
        {
          allowedUsers: [...newList, ...userList],
        },
      );
      // console.log(response);
      toast.success('Usuários inseridos');
      window.location.reload();
    } catch (err: any) {
      toast.error(err.message);
    }
  }

  async function handleNewFile(file) {
    const currentFile = file.files[0];

    try {
      const formData = new FormData();
      formData.append('file', currentFile);

      const response = await api.post('/upload/allowedUsers/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUserList(response.data);
    } catch (err: any) {
      toast.error(err.message);
    }
  }

  function handleSelectFile() {
    inputRef.current.click();
  }

  return (
    <>
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            zIndex: 9999,
          },
          content: {
            top: '40px',
            height: 'fit-content',
            margin: 'auto',
            width: '100%',
            maxWidth: '636px',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
        }}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
      >
        <button
          type="button"
          onClick={props.onRequestClose}
          style={{
            top: 20,
            right: 20,
            position: 'absolute',
            background: 'none',
            border: 'none',
            zIndex: 9999,
          }}
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>
        <S.ModalContent>
          <>
            <S.StyledPopUp {...props}>
              <S.TitleAlert>{props.alertContent?.title}</S.TitleAlert>

              <form>
                <S.InputWrapper {...props}>
                  <ul>
                    <li>
                      <label htmlFor={props?.inputs[2]?.name}>
                        {props?.inputs[2]?.label}
                      </label>
                      <div style={{ display: 'flex', gap: '18px' }}>
                        <div
                          className="input-container"
                          style={{ flexGrow: 1 }}
                        >
                          {userList.length <= 0 && (
                            <input
                              id={props?.inputs[2]?.name}
                              type="file"
                              ref={inputRef}
                              style={{ width: '100%', padding: 2, border: 0 }}
                              onChange={file => handleNewFile(file.target)}
                              placeholder={props.inputs[2].placeholder}
                            />
                          )}

                          {userList.length > 0 && [] && (
                            <ul className="users-container">
                              {userList.map(user => (
                                <li>
                                  <span>{user.name}</span>
                                  <span>{user.email}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <Button color="blueMidtone" onClick={handleSelectFile}>
                          {userList.length > 0
                            ? 'Escolher outro arquivo'
                            : 'Escolher arquivo'}
                        </Button>
                      </div>
                      <h6 style={{ marginTop: '20px' }}>
                        Foram encontrados
                        {userList !== [] ? ` ${userList?.length} ` : 0}
                        nomes
                      </h6>
                    </li>
                  </ul>
                </S.InputWrapper>

                <S.ButtonWrapper {...props}>
                  {props.alertContent?.dangerButtonText && (
                    <Button
                      color="redMidtone"
                      shadow={false}
                      onClick={props.alertContent?.onClickDanger}
                      size="small"
                    >
                      {props.alertContent?.dangerButtonText}
                    </Button>
                  )}

                  {props.alertContent?.ghostButtonText && (
                    <Button
                      color="blueMidtone"
                      shadow={false}
                      onClick={props.alertContent?.onClickGhost}
                      size="small"
                      ghost
                    >
                      {props.alertContent?.ghostButtonText}
                    </Button>
                  )}

                  {props.alertContent?.defaultButtonText && (
                    <Button
                      color="blueMidtone"
                      shadow={false}
                      size="small"
                      onClick={handleSubmit}
                    >
                      {props.alertContent?.defaultButtonText}
                    </Button>
                  )}
                </S.ButtonWrapper>
              </form>
            </S.StyledPopUp>
          </>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default NewUsersModal;
