import React, { ReactNode } from 'react';

import * as S from './styles';
import { IUser } from '../../models/user';
import EditButton from '../EditButton';

import { ReactComponent as Visa } from '../../assets/visa.svg';
import { ReactComponent as Mastercard } from '../../assets/mc.svg';

export interface PaymentMethodCardProps extends IUser {
  creditCard?: string | number;
  cardName?: string;
  creditCardNumber?: string | number;
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = props => {
  const getCardFlag = (name: string) => {
    switch (name) {
      case 'visa':
        return (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Visa style={{ width: '40px' }} />
            <p>Cartão Visa</p>
          </div>
        );
        break;
      case 'mastercard':
        return (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Mastercard style={{ width: '40px' }} />
            <p>Cartão Master card</p>
          </div>
        );

        break;

      default:
        return <p>Cartão de crédito</p>;
        break;
    }
  };

  return (
    <>
      <S.Container {...props}>
        <S.DataWrapper>
          <S.Title>Método de Pagamento</S.Title>
          <h3>{props.basicRole}</h3>
          <S.CreditContainer>
            <S.RowWrapper>{getCardFlag(props.cardName)}</S.RowWrapper>
            <h3>
              {props?.creditCardNumber
                ? `**** **** **** ${props?.creditCardNumber}`
                : 'Não Cadastrado'}
            </h3>
          </S.CreditContainer>
        </S.DataWrapper>
      </S.Container>
    </>
  );
};

export default PaymentMethodCard;
