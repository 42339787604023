import React from 'react';
import Button from '../Button';
import * as S from './styles';

export interface PersonalInfoCarProps {
  name?: string;
  email?: string;
  link?: string;
}

const PersonalInfoCard: React.FC<PersonalInfoCarProps> = props => {
  return (
    <>
      <S.Container>
        <h5>Informações</h5>
        <S.Header>
          <div className="first-col">
            <h6>Nome</h6>
          </div>
          <div className="second-col">
            <h6>E-mail</h6>
          </div>
          <div className="empty-col"> </div>
        </S.Header>
        <S.Body>
          <div className="first-col">
            <h6>{props.name}</h6>
          </div>
          <div className="second-col">
            <h6>{props.email}</h6>
          </div>

          <div className="empty-col"> </div>
          <div className="last-col">
            <Button link href={props.link || ''}>
              Ver mais
            </Button>{' '}
          </div>
        </S.Body>
      </S.Container>
    </>
  );
};

export default PersonalInfoCard;
