import React, { useState } from 'react';
import { useQuery } from 'react-query';
import GrowthCard from '../../components/GrowthCard';
import MostRequestedCard from '../../components/MostRequestedCard';
import { mostRequestedData } from '../../components/MostRequestedCard/fakeData';
import TotalCountCard from '../../components/TotalCountCard';
import api from '../../services/api';
import { useUsers } from '../../services/hooks/useUsers';

import * as S from './styles';
import Button from '../../components/Button';

const Home: React.FC = () => {
  const [totalArticles, setTotalArticles] = useState(0);

  const { data: users } = useUsers();

  const { data: keywords } = useQuery(
    ['keywords'],
    async () => {
      const response = await api.get('dashboard/count/keywords');

      const data = await response.data;

      return data;
    },
    {
      initialData: [],
    },
  );
  const { data: citations } = useQuery(
    ['citations'],
    async () => {
      const response = await api.get('/dashboard/count/citations');

      const data = await response.data;

      return data;
    },
    {
      initialData: [],
    },
  );

  const filterBySubscriptions = users.filter(
    e => !e?.subscriptions || e?.subscriptions?.enabled === false,
  );

  const filterBySubscriptionsPremium = users.filter(
    e => e?.subscriptions?.enabled === true,
  );

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <S.RowWrapper>
            {/* <GrowthCard type="anonymousUser" value={350} growth={2.5} /> */}
            <GrowthCard
              type="registeredUser"
              value={users.length}
              growth={-2.5}
            />
            <GrowthCard
              type="subscribedUser"
              value={filterBySubscriptionsPremium.length}
              growth={-2.5}
            />
          </S.RowWrapper>

          <S.RowWrapper>
            <TotalCountCard
              title="Total de palavras chaves"
              value={keywords.toLocaleString()}
            />
            <TotalCountCard
              title="Total de Citações"
              value={citations.toLocaleString()}
            />
          </S.RowWrapper>
          <S.RowWrapper>
            <div className="analytics-btn">
              <Button
                size="big"
                shadow
                color="blueMidtone"
                onClick={() =>
                  window.open(
                    'https://analytics.google.com/analytics/web/?authuser=6#/dashboard/3LqLnOyjQ_SmLEAnZkoB0Q/a214540241w295884923p256605133/',
                    '_blank',
                  )
                }
              >
                Acesso Google Analytics
              </Button>
            </div>
          </S.RowWrapper>
          <S.RowWrapper>
            <div className="analytics-btn" style={{ marginTop: '26px' }}>
              <Button
                size="big"
                ghost
                shadow
                color="blueMidtone"
                onClick={() =>
                  window.open(
                    'https://datastudio.google.com/reporting/467d84f9-e2e3-4563-9569-0a031249df99',
                    '_blank',
                  )
                }
              >
                Acesso Google Data Studio
              </Button>
            </div>
          </S.RowWrapper>
          {/*
          <S.RowWrapper>
            <MostRequestedCard
              type="keyWords"
              mostRequestedData={mostRequestedData}
            />
            <MostRequestedCard
              type="citations"
              mostRequestedData={mostRequestedData}
            />
            <MostRequestedCard
              type="articles"
              mostRequestedData={mostRequestedData}
            />
          </S.RowWrapper>
          <S.RowWrapper>
            <MostRequestedCard
              type="authors"
              mostRequestedData={mostRequestedData}
            />
            <MostRequestedCard
              type="journals"
              mostRequestedData={mostRequestedData}
            />
            <MostRequestedCard
              type="ppgs"
              mostRequestedData={mostRequestedData}
            />
          </S.RowWrapper> */}
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default Home;
