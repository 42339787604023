import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import Home from '../screens/Home';
import Articles from '../screens/Articles';
import ArticleDetails from '../screens/Articles/ArticlesDetails';
import Researchers from '../screens/Users/Researchers';
import ResearcherDetails from '../screens/Users/Researchers/ResearcherDetails';
import Billing from '../screens/Users/Billing';
import Authors from '../screens/Users/Authors';
import AuthorDetails from '../screens/Users/Authors/AuthorsDetails';
import Journals from '../screens/Users/Journals';
import PPGs from '../screens/Users/PPGs/Index';
import JournalDetails from '../screens/Users/Journals/JournalsDetails';
import PPGsDetails from '../screens/Users/PPGs/PPGsDetails';
import Subscriptions from '../screens/Subscriptions';
import SubscriptionsDetails from '../screens/Subscriptions/SubscriptionsDetails';
import Permissions from '../screens/Permissions';
import LoginPage from '../screens/LoginPage';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import RedirectPage from '../screens/RedirectPage';
import { useAuth } from '../hooks/auth';

const UserRoute = ({ user }) => (
  <>
    <div className="container">
      <Sidebar />

      <div className="wrapper">
        <Header userName={user?.name || 'Não cadastrado'} isHomePath />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/textos" component={Articles} />
          <Route
            exact
            path="/texto/sobre/:detalhe"
            component={ArticleDetails}
          />

          <Route exact path="/usuarios/pesquisadores" component={Researchers} />
          <Route
            exact
            path="/usuarios/pesquisadores/sobre/:id"
            component={ResearcherDetails}
          />
          <Route exact path="/cobranca/:id" component={Billing} />

          <Route exact path="/usuarios/autores" component={Authors} />
          <Route
            exact
            path="/usuarios/autores/sobre/:id"
            component={AuthorDetails}
          />

          <Route exact path="/usuarios/revistas" component={Journals} />
          <Route
            exact
            path="/usuarios/revista/sobre/:id"
            component={JournalDetails}
          />

          <Route exact path="/usuarios/ppgs" component={PPGs} />
          <Route
            exact
            path="/usuarios/ppgs/sobre/:id"
            component={PPGsDetails}
          />

          <Route exact path="/assinaturas" component={Subscriptions} />
          <Route
            exact
            path="/assinaturas/sobre/:id"
            component={SubscriptionsDetails}
          />
          <Route exact path="/redirect/:id" component={RedirectPage} />

          <Route exact path="/permissoes" component={Permissions} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  </>
);

const AuthRoute = () => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <Redirect to="/login" />
  </Switch>
);

export default function Routes() {
  const { user, signed, signOut } = useAuth();

  if (signed && user.plan.basicRole === 'CaciqAdmin') {
    return <UserRoute user={user} />;
  }

  return <AuthRoute />;
}
