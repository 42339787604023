/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import getClient from '../client/elasticsearch';

export function useUsersElastic(
  search: string,
  currentPageNumber: number,
  years?: string[],
  searchTerm?: string,
) {
  return useQuery(
    ['users', search, currentPageNumber, years, searchTerm],
    () => useUsersE(search, currentPageNumber, years),
    {
      initialData: { users: [], total: 0 },
    },
  );
}

async function useUsersE(
  search,
  currentPageNumber,
  years = [],
  searchTerm = '',
) {
  const defaultSearch = {
    index: search,
    from: currentPageNumber * 5,
    size: 5,
    body: {
      sort: [{ 'indexes.IC-T': { order: 'desc' } }],
    },
  };

  const customSearch = {
    index: search,
    from: currentPageNumber * 5,
    size: 5,
    body: {
      query: {
        bool: {
          should: [
            {
              match: {
                name: searchTerm,
              },
            },
            {
              match: {
                indexes: searchTerm,
              },
            },
            {
              bool: {
                should: years?.map(year => ({
                  match: { 'articlesRel.publicationYear': year },
                })),
              },
            },
          ],
        },
      },
    },
  };

  const response = await getClient().search(
    years !== [] || searchTerm !== '' ? customSearch : defaultSearch,
  );
  const data = await response.hits.hits;
  const totalData: any = await response.hits.total;
  return { users: data, total: totalData?.value };
}
