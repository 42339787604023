import React, { ReactNode } from 'react';
import { ReactComponent as PositiveGrowthIcon } from '../../assets/positive-growth.svg';
import { ReactComponent as NegativeGrowthIcon } from '../../assets/negative-growth.svg';
import AnonymousUserIcon from '../../assets/anonymous-user.svg';
import RegisteredUserIcon from '../../assets/registered-user.svg';
import SubscribedUserIcon from '../../assets/subscribed-user.svg';
import ResearcherIcon from '../../assets/researcher-user.svg';
import AuthorIcon from '../../assets/author-user.svg';
import JournalIcon from '../../assets/journal-user.svg';
import PPGIcon from '../../assets/ppg-user.svg';

import * as S from './styles';

export interface GrowthCardProps {
  size?: 'small' | 'medium';
  type:
    | 'anonymousUser'
    | 'registeredUser'
    | 'subscribedUser'
    | 'researcher'
    | 'author'
    | 'journal'
    | 'ppg';

  value?: number;
  growth?: number;
}

const GrowthCard: React.FC<GrowthCardProps> = props => {
  return (
    <>
      <S.Container {...props}>
        <div>
          {props.type === 'anonymousUser' ? (
            <img src={AnonymousUserIcon} alt="Usuário anônimo" />
          ) : props.type === 'registeredUser' ? (
            <img src={RegisteredUserIcon} alt="Usuário registrado" />
          ) : props.type === 'subscribedUser' ? (
            <img src={SubscribedUserIcon} alt="Usuário assinante" />
          ) : props.type === 'researcher' ? (
            <img src={ResearcherIcon} alt="Assinaturas Pesquisador" />
          ) : props.type === 'author' ? (
            <img src={AuthorIcon} alt="Assinaturas Autor" />
          ) : props.type === 'journal' ? (
            <img src={JournalIcon} alt="Assinaturas Periódico" />
          ) : props.type === 'ppg' ? (
            <img src={PPGIcon} alt="Assinaturas PPGs" />
          ) : null}
        </div>
        <S.DataWrapper>
          <div>
            <S.Title>
              {props.type === 'anonymousUser'
                ? 'Acesso Usuários Anônimos'
                : props.type === 'registeredUser'
                ? 'Usuários Registrados'
                : props.type === 'subscribedUser'
                ? 'Usuários Assinantes'
                : props.type === 'researcher'
                ? 'Assinaturas Pesquisador'
                : props.type === 'author'
                ? 'Assinaturas Autor'
                : props.type === 'journal'
                ? 'Assinaturas Periódico'
                : props.type === 'ppg'
                ? 'Assinaturas PPGs'
                : null}
            </S.Title>
          </div>
          <S.ValuesWrapper>
            <S.Value>{props.value}</S.Value>
            {/* <S.GrowthWrapper>
              {props.growth > 0 ? (
                <PositiveGrowthIcon />
              ) : (
                <NegativeGrowthIcon />
              )}
              <S.Growth>{props.growth}%</S.Growth>
            </S.GrowthWrapper> */}
          </S.ValuesWrapper>
        </S.DataWrapper>
      </S.Container>
    </>
  );
};

export default GrowthCard;
