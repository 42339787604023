import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import DetailsSection from '../../../../components/DetailsSection';
import PersonalData from '../../../../components/PersonalData';
import { IUser } from '../../../../models/user';
import { useUserDetail } from '../../../../services/hooks/useUserDetail';
import * as S from './styles';

export interface UserDetailsProps extends IUser {
  mostCitedKeyWords: any;
  mostCitedArticles: any;
  professors: any;
  valuationArea: string;
  coordinator: string;
  university: string;
  indexes: any;
  cpf?: string;
  birthDate?: string;
  adress?: string;
  phone?: string;
}

const ResearcherDetails: React.FC<UserDetailsProps> = props => {
  const { id } = useParams<any>();
  const { data: user, isLoading } = useUserDetail(id);

  const [birthDate, setBirthDate] = useState('2000-01-01');

  useEffect(() => {
    if (!isLoading) {
      const formatBirthDate = user?.birthDate?.split('T');
      setBirthDate(formatBirthDate[0]);
    }
  }, []);

  const formatedBirthDate = format(
    parseISO(birthDate.toString()),
    'dd/MM/yyyy',
  );

  console.log(user);

  const researcherItems = [
    {
      id: 0,
      title: 'Sobre',
      href: `/usuarios/pesquisadores/sobre/${id}`,
      disabled: false,
    },
    {
      id: 1,
      title: 'Cobrança',
      disabled: !user?.subscriptions || false,
      href: `/cobranca/${user?.subscriptions?.id}`,
    },
  ];

  return (
    <>
      <S.MainContainer>
        <S.ContentWrapper>
          <DetailsSection paths={researcherItems} />
          <S.HeaderWrapper>
            <S.TitleWrapper>
              <S.TypeTitle>Pesquisador</S.TypeTitle>
              <h3>{user?.name}</h3>
            </S.TitleWrapper>
          </S.HeaderWrapper>
          <S.ColumnWrapper>
            <h4>Dados</h4>
            <PersonalData title="Nome completo" data={user?.name} />
            <PersonalData title="E-mail" data={user?.email} />
            <PersonalData title="CPF" data={user?.CPF} />
            <PersonalData title="Nascimento" data={formatedBirthDate} />
          </S.ColumnWrapper>
        </S.ContentWrapper>
      </S.MainContainer>
    </>
  );
};

export default ResearcherDetails;
