import { createGlobalStyle, css } from 'styled-components';
import mq from './mq';

/* Palette */

export const theme = {
  colors: {
    /* Red */
    redDefault: '#FE4223',
    redMidtone: '#BC1B01',
    redDark: '#891401',
    redLight: '#FE9B8A',

    /* Blue */
    blueDefault: '#1DCFCF',
    blueMidtone: '#17A1A1',
    blueDark: '#107474',
    blueLight: '#94F0F0',

    /* Purple */
    purpleDefault: '#932D91',
    purpleMidtone: '#6D216C',
    purpleDark: '#461645',
    purpleLight: '#D982D7',

    /* Neutral */
    grey1: '#161820',
    grey2: '#49565A',
    grey3: '#636E6F',
    grey4: '#A5ADBA',
    grey5: '#A9B3C4',
    grey6: '#DADEE5',
    grey7: '#EFF4FC',
    grey8: '#F7F7F7',
    white: '#fff',

    /* Alert */

    success: '#4CBD25',
    wrong: '#F9DA6D',
    failure: '#F2705E',
  },

  fonts: {
    mulish: {
      h1: ['3rem Mulish, sans-serif; font-weight: 900'],
      h2: ['2.5rem Mulish, sans-serif; font-weight: bold'],
      h3: ['1.87rem Mulish, sans-serif; font-weight: bold'],
      h4: ['1.56rem Mulish, sans-serif;'],
      h5: ['1.25rem Mulish, sans-serif;'],
      h6: ['0.75rem Mulish, sans-serif; letter-spacing: 1px;'],
      p: ['0.87rem Mulish, sans-serif;'],
      button: ['0.87rem Mulish, sans-serif; '],
    },
    workSans: {
      p: ['1rem Work Sans, sans-serif; '],
    },
  },
};

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    line-height: 120%;

    list-style-type: none;

  }

  html {
    min-height: 100%;
  }

  body {
    background: #FFFFFF;
    -webkit-font-smoothing: antialiased;
    min-height: 100%;
    overflow-x: hidden;
    position: relative;
  }

  a {
    text-decoration: none;
  }

  #root {
    width: 100%;
    height:100%;
  }

  /* .app-main-container{
    width: 100%;
    height:100%;

    padding-top:259px;
  } */

  button {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6, button {
    font-family: 'Mulish', sans-serif;
  }

  p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
  }

  a {
  text-decoration: none;
}


  /* .react-switch-handle {
    background: #94F0F0 !important;
  } */


  h1{
      ${theme.fonts.mulish.h1};
  }
  h2{
      ${theme.fonts.mulish.h2};
  }
  h3{
      ${theme.fonts.mulish.h3};
  }
  h4{
      ${theme.fonts.mulish.h4};
  }
  h5{
      ${theme.fonts.mulish.h5};
  }
  h6{
      ${theme.fonts.mulish.h6};
  }

  .container {
    display: flex;
   justify-content: flex-start;
   width: 100%;
    height: 100%;
  }

  .wrapper {
    width: 100%;
  }


    .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

  .table-green-tag,
  .table-purple-tag,
  .table-green-outline-tag,
  .table-red-tag {
    font: ${theme.fonts.mulish.p};
    font-weight: 500;
    font-size: 0.75rem;
    color: #fff;

    text-align: center;
    letter-spacing: 1px;

    background: ${theme.colors.blueMidtone};
    padding: 4.5px 6.5px;
    border-radius: 8px;
  }

  .table-purple-tag {
    background: ${theme.colors.purpleDefault};
  }

  .table-green-outline-tag {
    color: ${theme.colors.blueMidtone};
    background: #FFF;
    border: 1px solid ${theme.colors.blueMidtone};
  }

  .table-red-tag {
    background: ${theme.colors.redMidtone};
  }

  .MTableHeader-header-13 {
    z-index: 0;
  }

  /* Tablet */

  ${mq.tablet(css`
    h1 {
      font-size: 2.87rem;
    }
    h2 {
      font-size: 2.37rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.43rem;
    }
    h5 {
      font-size: 1.12rem;
    }
    h6 {
      font-size: 0.62rem;
    }
    p {
      font-size: 0.87rem;
    }
  `)}

  /* Mobile */

  ${mq.mobile(
    css`
      h1 {
        font-size: calc(2.87rem - 0.125rem);
      }
      h2 {
        font-size: calc(2.37rem - 0.125rem);
      }
      h3 {
        font-size: calc(1.75rem - 0.125rem);
      }
      h4 {
        font-size: calc(1.43rem - 0.125rem);
      }
      h5 {
        font-size: calc(1.12rem - 0.125rem);
      }
      h6 {
        font-size: calc(0.62rem - 0.125rem);
      }
      p {
        font-size: calc(0.87rem - 0.125rem);
      }
    `,
  )}
`;
