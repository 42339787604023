import styled, { css } from 'styled-components';

import { EditModalProps } from '.';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
`;

export const TitleContainer = styled.div<EditModalProps>`
  display: flex;
  flex-direction: row;

  img {
    margin-right: 18px;
  }
`;

export const Title = styled.h5<EditModalProps>`
  display: flex;
  flex: 1;
  justify-content: center;

  color: ${({ theme }) => theme.colors.purpleDefault};
  font-weight: normal;
  font-size: 20px;
`;

export const Total = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3};
  span {
    font-weight: bold;
    font-size: 20px;

    letter-spacing: 0.5px;

    /* Caciq/ Blue/ Midtone */

    color: #17a1a1;
  }
`;

export const ButtonWrapper = styled.div<EditModalProps>`
  display: flex;
  gap: 18px;
  width: 100%;
  padding-top: 34px;

  button {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const StyledPopUp = styled.div`
  width: 100%;
  max-width: 636px;
  padding: 20px;
  border-radius: 10px;

  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;

  form {
    width: 100%;

    ul {
      width: 100%;

      li {
        label {
          display: block;
          margin-bottom: 4px;
        }
        & + li {
          margin-top: 12px;
        }
      }
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const TitleAlert = styled.h4`
  font-size: 1.56rem;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.grey2};
`;

export const InputWrapper = styled.div<EditModalProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label {
    margin: 16px 0;
    font: ${({ theme }) => theme.fonts.mulish.h6};
    color: ${({ theme }) => theme.colors.blueMidtone};
    font-size: 0.75rem;
  }

  input {
    height: 45px;
    width: 100%;
    border: 1.5px solid ${({ theme }) => theme.colors.blueMidtone};
    border-radius: 10px;
    padding: 10px;

    &:disabled {
      border: 1.5px solid #dadee5;
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.grey5};
    }
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 18px;
`;
