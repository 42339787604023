import styled, { css } from 'styled-components';

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 35px;

  ul {
    display: flex;
    flex-direction: row;

    li {
      margin: 0 20px 0 0;
    }
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface ButtonProp {
  disabledProp?: boolean;
}

export const ButtonSection = styled.div<ButtonProp>`
  a {
    height: 53px;
    display: block;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px;
    text-decoration: none;
    color: #636e6f;
    transition: background 0.5s, color 0.5s, height 0.5s;

    &:hover {
      background: ${({ theme }) => theme.colors.blueMidtone};
      color: #fff;

      .divLine {
        display: block;
      }
    }

    .divLine {
      display: none;
      margin-top: 6px;
      text-align: center;
    }

    svg {
      margin-right: 12px;
    }
  }

  ${prop => {
    if (prop.disabledProp) {
      return css`
        a {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.5;
        }
      `;
    }
    return css``;
  }}

  .active-link {
    background: ${({ theme }) => theme.colors.blueMidtone};
    color: #ffffff;
    .divLine {
      display: block;
    }
  }

  button {
    display: block;
    background: #fff;
    border: 0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 18px;
    text-decoration: none;
    color: #636e6f;

    transition: background 0.5s, color 0.5s, height 0.5s;

    &:hover {
      background: ${({ theme }) => theme.colors.blueMidtone};
      color: #fff;

      .divLine {
        display: block;
      }
    }

    .divLine {
      display: none;
      margin-top: 6px;
      text-align: center;
    }
  }
`;
